import React from "react";
import { Spin, Alert, Form, Input, Row, Col, Button, message } from "antd";
import { useGlobalState } from "../../context/context";
import CustomModal from "../../component/CustomModal/CustomModal";
import footerContent from "../../services/pageContent/footer.json";
import { postQueryMail } from "../../services/sendMail";

const FeedBackForm = ({ isFeedBkForm, onCloseFeedBkForm }) => {
  const [{ user_info }, dispatch] = useGlobalState();
  const [contactForm] = Form.useForm();

  const [loading, setLoading] = React.useState(false);
  const onFinish = (info) => {
    setLoading(true);
    postQueryMail(
      info,
      (msg) => {
        message.success("Thank you we will connect with you! Soon.");
        contactForm.resetFields(["requirement_desc"]);
        setLoading(false);
        onCloseFeedBkForm();
      },
      () => {
        setLoading(false);

        message.error("Something wents wrong! Try again later.");
      }
    );
  };

  const openContactModal = () => {};
  React.useEffect(() => {
    if (isFeedBkForm && user_info?.name) {
      contactForm.setFieldsValue({ name: user_info?.name, email: user_info?.email });
    }
  }, [isFeedBkForm]);
  return (
    <>
      <CustomModal isModalVisible={isFeedBkForm} handleCancel={onCloseFeedBkForm} width={450} height={550} borderRadius>
        <div className='conatact-us-modal'>
          <h1 className='constact-modal-title'>Contact Us</h1>
          <Form form={contactForm} name='contact-us-form' layout='vertical' requiredMark={false} className='contact-us-wrapper' onFinish={onFinish}>
            <Form.Item
              label='Name'
              name='name'
              rules={[
                {
                  required: true,
                  message: "Please writer name!",
                },
              ]}>
              <Input placeholder='Name' />
            </Form.Item>
            <Form.Item
              label='Email'
              name='email'
              rules={[
                {
                  required: true,
                  message: "Please write your email!",
                },
              ]}>
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item
              label='Queries'
              name='queries'
              rules={[
                {
                  required: true,
                  message: "Please write somthing about your Queries!",
                },
              ]}>
              <Input.TextArea rows={5} placeholder='A Breif About your Queries' />
            </Form.Item>

            <Form.Item noStyle>
              <Button loading={loading} type='primary' htmlType='submit' className='custom-btn contact-us-btn'>
                Contact Us
              </Button>
            </Form.Item>
          </Form>
        </div>
      </CustomModal>
    </>
  );
};

export default FeedBackForm;
