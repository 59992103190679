import React from "react";
import { Row, Col, Divider, Carousel, Space, Tabs } from "antd";
import GoogleDriveLogo from "../../assest/google-drive.png";
import OnDriveLogo from "../../assest/ondrive-logo.png";
import OracleLogo from "../../assest/oracle-logo.png";
import SapLogo from "../../assest/sap-logo.png";
import UipathLogo from "../../assest/uipath-vector-logo.png";

import ArrowCurveDown from "../../assest/arrow-curve-down.svg";
import ArrowCurveUp from "../../assest/arrow-curve-up.svg";

import OriginalImg from "../../assest/img/original-img.png";
import DetectedTextImg from "../../assest/img/detected-text-img.png";
import DetectedRecoImg from "../../assest/img/detected-reco-img.png";
import DenoisedImg from "../../assest/img/denoised-img.png";
import CorrectOrientImg from "../../assest/img/correct-orient-img.png";
import langContent from "../../services/pageContent/ImgProcessStep.json";
import marketinContentLang from "../../services/pageContent/MarketingSec.json";
import BannerCarousel from "../BannerCarousel/BannerCarousel";
import ExtractImg1 from "../../assest/extractIt/extractImg1.jpeg";
import ExtractImg2 from "../../assest/extractIt/extractImg2.png";
import ExtractImg3 from "../../assest/extractIt/extractImg3.png";
import ExtractImg4 from "../../assest/extractIt/extractImg4.jpg";
import { useGlobalState } from "../../context/context";

import "./ImgProcessStep.scss";

const { TabPane } = Tabs;

const MarketingSectionOne = ({ marketinContent }) => {
  return (
    <section className='marketing-section-one section'>
      <Row justify='space-between' align='start' className='sec-row' data-aos-duration='800'>
        <Col md={14} sm={24} data-aos='fade-right'>
          <h1 className='section-title-md'>
            <Space direction='vertical' size={0}>
              <div>{marketinContent.secTitle_1[0]}</div>
              <div>{marketinContent.secTitle_1[1]}</div>
            </Space>
          </h1>
          <p className='section-desc-md'>{marketinContent.secDesc_1}</p>
        </Col>
        <Col md={10} sm={24} className='text-extract-img' data-aos='fade-left'>
          <div>
            <BannerCarousel />
          </div>
        </Col>
      </Row>
    </section>
  );
};
const MarketingSectionTwo = ({ marketinContent }) => {
  return (
    <section className='marketing-section-two section'>
      <Row justify='space-between' gutter={[0, 32]} align='start'>
        <Col md={13} sm={24} className='company-logo' data-aos='fade-right' data-aos-duration='800'>
          <Space size={40} align='center'>
            <div>
              <Space size='large' direction='vertical'>
                <img src={UipathLogo} alt='Logo' />
                <img src={OnDriveLogo} alt='Logo' />
                <img src={OracleLogo} alt='Logo' />
              </Space>
            </div>
            <div>
              <Space direction='vertical' size='large'>
                <img src={SapLogo} alt='Logo' />
                <img src={GoogleDriveLogo} alt='Logo' />
              </Space>
            </div>
          </Space>
        </Col>
        <Col md={11} sm={24} data-aos='fade-left' data-aos-duration='800'>
          <h1 className='section-title-md'>
            <Space direction='vertical' size={0}>
              <div>{marketinContent.secTitle_2[0]}</div>
              <div>{marketinContent.secTitle_2[1]}</div>
            </Space>
          </h1>
          <p className='section-desc-md'>{marketinContent.secDesc_2}</p>
        </Col>
      </Row>
    </section>
  );
};

const CorrectOrientTab = ({ pageContent }) => {
  return (
    <Row gutter={[64, 0]} justify='center' className='process-gallery-row'>
      <Col data-aos='zoom-in' data-aos-delay='500' data-aos-duration='600'>
        <div className='process-img-box'>
          <img src={ExtractImg1} alt='Original upload image' />
        </div>
        <div className='label-dark'>{pageContent["1st"]}</div>
        <img src={ArrowCurveUp} alt='Arrow up' className='arrow-line-up-img' width={180} />
      </Col>

      <Col data-aos='zoom-in' data-aos-delay='700' data-aos-duration='600'>
        <img src={ArrowCurveDown} alt='Arrow Down' className='arrow-line-down-img' width={180} />
        <div className='process-img-box'>
          <img src={ExtractImg2} alt='Correct Orientation image' />
        </div>
        <div className='label-dark'>{pageContent["3rd"]}</div>
      </Col>

      <Col data-aos='zoom-in' data-aos-delay='900' data-aos-duration='600'>
        <div className='process-img-box'>
          <img src={ExtractImg3} alt='Detected Recognized image' />
        </div>
        <div className='label-dark'>{pageContent["4th"]}</div>
        <img src={ArrowCurveUp} alt='Arrow up' className='arrow-line-up-img' width={180} />
      </Col>

      <Col data-aos='zoom-in' data-aos-delay='1000' data-aos-anchor-placement='top-bottom' data-aos-duration='600'>
        <div className='process-img-box'>
          <img src={ExtractImg4} alt='Detected Text image' />
        </div>
        <div className='label-dark'>{pageContent["5th"]}</div>
      </Col>
    </Row>
  );
};
const DenoisedTab = ({ correctOrient, denoised, pageContent }) => {
  return (
    <Row gutter={[48, 0]} justify='center' className='process-gallery-row'>
      <Col data-aos='zoom-in' data-aos-delay='700' data-aos-duration='600'>
        <div className='process-img-box'>
          <img src={DenoisedImg} alt='Denoised image' />
        </div>
        <div className='label-dark'>{pageContent["1st"]}</div>
        <img src={ArrowCurveUp} alt='Arrow up' className='arrow-line-up-img' width={180} />
      </Col>
      <Col data-aos='zoom-in' data-aos-delay='900' data-aos-duration='600'>
        <img src={ArrowCurveDown} alt='Arrow Down' className='arrow-line-down-img' width={180} />
        <div className='process-img-box'>
          <img src={CorrectOrientImg} alt='Correct Orientation image' />
        </div>
        <div className='label-dark'>{pageContent["2nd"]}</div>
      </Col>
      <Col data-aos='zoom-in' data-aos-delay='1100' data-aos-duration='600'>
        <div className='process-img-box'>
          <img src={DetectedRecoImg} alt='Detected Recognized image' />
        </div>
        <div className='label-dark'>{pageContent["4th"]}</div>
        <img src={ArrowCurveUp} alt='Arrow up' className='arrow-line-up-img' width={180} />
      </Col>
      <Col data-aos='zoom-in' data-aos-delay='1300' data-aos-duration='600'>
        <div className='process-img-box'>
          <img src={DetectedTextImg} alt='Detected Text image' />
        </div>
        <div className='label-dark'>{pageContent["5th"]}</div>
      </Col>
    </Row>
  );
};

const ImgProcessStep = () => {
  const [globalState, dispatch] = useGlobalState();

  const [activeKey, setActiveKey] = React.useState("1");
  const [pageContent, setPageContent] = React.useState(langContent[globalState.lang]);
  const [marketinContent, setMarketinContent] = React.useState(marketinContentLang[globalState.lang]);
  const tabDesc = (
    <p data-aos='zoom-in' data-aos-delay='300'>
      {pageContent.secDesc}
    </p>
  );
  React.useEffect(() => {
    setPageContent(langContent[globalState.lang]);
    setMarketinContent(marketinContentLang[globalState.lang]);
  }, [globalState.lang]);

  // React.useEffect(() => {
  //   let id = setInterval(() => {
  //     if (activeKey === "1") {
  //       setActiveKey("2");
  //     } else {
  //       setActiveKey("1");
  //     }
  //   }, 3000);
  //   return () => clearInterval(id);
  // }, []);

  return (
    <>
      <section className='extract-prv-section section'>
        <h1 className='section-title' data-aos='fade-down' data-aos-duration='600'>
          {pageContent.secTitle}
        </h1>
        <div className='extract-prv-wrapper'>
          <Tabs activeKey={activeKey} onChange={() => {}} animated>
            <TabPane tab='Correct Orentation' key='1' className='tab-content'>
              {tabDesc}
              <CorrectOrientTab pageContent={pageContent} />
            </TabPane>
            <TabPane tab='Remove Noise' key='2' className='tab-content'>
              {tabDesc}
              <DenoisedTab pageContent={pageContent} />
            </TabPane>
          </Tabs>
        </div>
      </section>
      <Divider />
      <MarketingSectionOne marketinContent={marketinContent} />
      <Divider />
      <MarketingSectionTwo marketinContent={marketinContent} />
      <Divider />
    </>
  );
};

export default ImgProcessStep;
