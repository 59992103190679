import React from "react";
import { Row, Tooltip, Col, Space, Form, Input, Divider, Collapse, Button, message, Menu, Dropdown, Select } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import MailIcon from "../../assest/open-mail-icon.svg";
import EmailIcon from "../../assest/mail.svg";
import PhoneIcon from "../../assest/phone-icon.svg";
import FacebookIcon from "../../assest/facebook-icon.svg";
import LinkdinIcon from "../../assest/linkdin-icon.svg";
import TwitterIcon from "../../assest/twitter-icon.svg";
import AlazkaLogo from "../../assest/alazka-logo.png";
import FAQS from "../FAQ/FAQS";
import "./FooterConsole.scss";
import { setLocalStorage, getLocalStorage } from "../utils/utils";
import { useGlobalState } from "../../context/context";
import footerContent from "../../services/pageContent/footer.json";
import { Link, useHistory } from "react-router-dom";
import FeedBackForm from "../FeedBackForm/FeedBackForm";
import actionTypes from "../../context/actions";
const { Option } = Select;

const FooterConsole = ({ faqs = undefined }) => {
  const [{ user_info, lang }, dispatch] = useGlobalState();

  const history = useHistory();
  const [isFeedBkForm, setFeedBkForm] = React.useState(false);
  const onChangeLang = (value) => {
    setLocalStorage("lang", value);
    dispatch({ type: actionTypes.LANG, payload: value });
  };

  const onOpenFeedBkForm = () => {
    setFeedBkForm(true);
  };

  const onCloseFeedBkForm = () => {
    setFeedBkForm(false);
  };
  return (
    <div className='footer-section' id='get-in-touch'>
      {faqs !== undefined && (
        <>
          <div className='faq-container'>
            <Row gutter={[0, 24]} align='middle' justify='center'>
              <Col xs={24} lg={18}>
                <FAQS faqs={faqs} />
              </Col>
              <Col xs={24} lg={18}>
                <Button className='feedback-btn' onClick={onOpenFeedBkForm}>
                  Need Support? Contact Us
                </Button>
              </Col>
            </Row>
          </div>
          <Divider />
        </>
      )}
      <Row className='footer-container'>
        {!user_info?.user && (
          <Col span={24} className='footer-sec-two'>
            <Row justify='space-between'>
              <Col>
                <Space direction='vertical' size='middle'>
                  <div className='footer-quote'>Get in touch</div>
                  <div>
                    <a href={`mailto:${footerContent[lang].email}`}>
                      <img className='email-icon' src={EmailIcon} alt='Email' /> {footerContent[lang].email}
                    </a>
                  </div>
                  <div className='term-service-m-view'>
                    <Link to='/terms-of-service' style={{ textAlign: "center" }}>
                      {footerContent[lang].termsOfService}
                    </Link>{" "}
                    <span className='vt-line-m'>|</span> <Link to='/privacy-policy'>{footerContent[lang].privacyPolicy}</Link>
                  </div>
                </Space>
              </Col>
              <Col>
                <Space direction='vertical' align='end' size='middle'>
                  <Space direction='horizontal' size='middle' align='center' className='social-icons'>
                    <a href={footerContent[lang].fbLink} target='_blank'>
                      <img className='social-icon' src={FacebookIcon} alt='facebook' />
                    </a>
                    <a href={footerContent[lang].twitterLink} target='_blank'>
                      <img className='social-icon' src={TwitterIcon} alt='Twitter' />
                    </a>
                    <a href={footerContent[lang].linkdinLink} target='_blank'>
                      <img className='social-icon' src={LinkdinIcon} alt='Linkdin' />
                    </a>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24} className='footer-sec-bottom'>
          <Row justify='space-between' align='middle'>
            <Col>
              <div className='app-logo' onClick={() => history.push("/")}>
                <img src={AlazkaLogo} alt='Alazka' />
              </div>
            </Col>
            <Col>
              <div>
                <Link to='/terms-of-service' className='terms-service' style={{ textAlign: "center" }}>
                  {footerContent[lang].termsOfService}
                </Link>{" "}
                <span className='vt-line'>|</span>{" "}
                <Link to='/privacy-policy' className='terms-service'>
                  {footerContent[lang].privacyPolicy}
                </Link>
              </div>
            </Col>
            <Col>
              <div id='google_translate_element'></div>
              <Tooltip title='Select Language' placement='left'>
                <Select
                  value={
                    <>
                      <GlobalOutlined /> {lang}
                    </>
                  }
                  className='select-language'
                  style={{ width: 120 }}
                  onChange={onChangeLang}>
                  <Option value='English'>English</Option>
                  <Option value='العربية'>العربية</Option>
                </Select>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>

      {isFeedBkForm && <FeedBackForm isFeedBkForm={isFeedBkForm} onCloseFeedBkForm={onCloseFeedBkForm} />}
    </div>
  );
};

export default FooterConsole;
