import React, { useCallback, useRef, useState } from "react";
import ExtractionImg from "../../assest/banner-img.svg";
import { Row, Col, Form, Input, Button, message, Divider, Space, Alert } from "antd";
import "./LoginModal.scss";
import { AuthLogin, authenticate, userRegister, getForgortPasswordLink } from "../../services/auth";
import { useHistory, useLocation } from "react-router-dom";
import actionTypes from "../../context/actions";
import { useGlobalState, useOCRState } from "../../context/context";
import { LoginSocialGoogle, LoginSocialMicrosoft } from "reactjs-social-login";
import { GoogleLoginButton, MicrosoftLoginButton } from "react-social-login-buttons";
import { msgModal } from "../../component/utils/utils";
import pageContent from "../../services/pageContent/signInLeftContent.json";
import { gClient_Id, mClient_Id, redirectUri } from "../../services/webapi";

import AzureAuthenticationButton from "./AuthComponent";
import { AccountInfo } from "@azure/msal-browser";

const SocialLoign = ({ onSocialSignIn }) => {
  const [{ lang }, dispatch] = useGlobalState();
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const [currentUser, setCurrentUser] = useState();

  const googleRef = useRef(null);
  const microsoftRef = useRef(null);

  const onLoginStart = () => {};

  const onLogoutFailure = useCallback(() => {
    alert("logout fail");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");

    alert("logout success");
  }, []);

  const onAuthenticated = async (account) => {
    onSocialSignIn({ username: account?.name, email: account?.username, password: "Something@123", isSocialLogin: true });
  };

  const onLogout = useCallback(() => {
    switch (provider) {
      case "google":
        googleRef.current?.onLogout();
        break;
      case "microsoft":
        microsoftRef.current?.onLogout();
        break;
      default:
        break;
    }
  }, [provider]);
  return (
    <Space direction='vertical' style={{ width: "100%" }}>
      <LoginSocialGoogle
        ref={googleRef}
        client_id={gClient_Id ?? ""}
        onLogoutFailure={onLogoutFailure}
        onLoginStart={onLoginStart}
        redirect_uri={redirectUri}
        onLogoutSuccess={onLogoutSuccess}
        onResolve={({ provider, data }) => {
          onSocialSignIn({ username: data.name, email: data.email, password: "Something@123", isSocialLogin: true });
        }}
        onReject={(err) => {
          console.log(err);
        }}>
        <GoogleLoginButton align='center' text='SignIn with Google' className='social-login-btn google-btn' />
      </LoginSocialGoogle>

      <AzureAuthenticationButton onAuthenticated={onAuthenticated} />
    </Space>
  );
};

const LoginModal = ({ closeSignInModal, resterModel = false }) => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [globalState, dispatch] = useGlobalState();
  const [{ filesToProccess }, ocrDispatch] = useOCRState();

  const { lang } = globalState;
  const [hasFeed, setHasFeed] = React.useState(false);

  const [isForgotPassword, setForgotPassword] = React.useState(false);
  const [isResiter, setRegister] = React.useState(resterModel || false);

  const [isError, setError] = React.useState("");
  const [isMsg, setMsg] = React.useState("");

  const [form] = Form.useForm();
  const [forgotform] = Form.useForm();

  const onSignIn = (userInfo) => {
    if (!userInfo?.isSocialLogin) {
      form.validateFields().then((r) => {
        if (!/[A-Z]/.test(userInfo.password)) {
          form.setFields([
            {
              name: "password",
              errors: ["password should cantain atleast one uppercase letter!"],
            },
          ]);
          return false;
        }

        if (!/[a-z]/.test(userInfo.password)) {
          form.setFields([
            {
              name: "password",
              errors: ["password should cantain atleast one lowercase letter!"],
            },
          ]);
          return false;
        }
        if (!/[0-9]/.test(userInfo.password)) {
          form.setFields([
            {
              name: "password",
              errors: ["password should cantain atleast one number!"],
            },
          ]);
          return false;
        }
        if (!/[#?!@$%^&*-]/.test(userInfo.password)) {
          form.setFields([
            {
              name: "password",
              errors: ["password should cantain atleast one special caracter #?!@$%^&*-"],
            },
          ]);
          return false;
        }
        setError("");
        setLoading(true);
        AuthLogin(
          userInfo,
          (r) => {
            closeSignInModal();
            setLoading(false);
            authenticate(r.token, () => {
              dispatch({ type: actionTypes.SET_USER, payload: r.user });
              history.push("/ocr-process");
              ocrDispatch({ type: actionTypes.OCR_CLEAR_STATE, payload: {} });
            });
          },
          (err) => {
            setLoading(false);
            setError(err);
          }
        );
      });
    } else {
      setError("");
      dispatch({ type: actionTypes.LOADING, payload: {} });
      closeSignInModal();
      AuthLogin(
        userInfo,
        (r) => {
          setLoading(false);
          authenticate(r.token, () => {
            dispatch({ type: actionTypes.LOADING, payload: {} });
            history.push("/ocr-process");
            ocrDispatch({ type: actionTypes.OCR_CLEAR_STATE, payload: {} });
          });
        },
        (err) => {
          dispatch({ type: actionTypes.LOADING, payload: {} });
          setError(err.msg);
        }
      );
    }
  };
  const onForgotPs = (userInfo) => {
    setLoading(true);
    setError("");
    setMsg("");
    getForgortPasswordLink(
      userInfo,
      (r) => {
        setLoading(false);
        setMsg(r.msg);
        message.success(r.msg);
        closeSignInModal();
        form.resetFields(["email"]);
      },
      (err) => {
        setLoading(false);
        setError(err);
      }
    );
  };

  const onRegister = (userInfo) => {
    form
      .validateFields()
      .then((r) => {
        if (!/[A-Z]/.test(userInfo.password)) {
          form.setFields([
            {
              name: "password",
              errors: ["password should cantain atleast one uppercase letter!"],
            },
          ]);
          return false;
        }

        if (!/[a-z]/.test(userInfo.password)) {
          form.setFields([
            {
              name: "password",
              errors: ["password should cantain atleast one lowercase letter!"],
            },
          ]);
          return false;
        }
        if (!/[0-9]/.test(userInfo.password)) {
          form.setFields([
            {
              name: "password",
              errors: ["password should cantain atleast one number!"],
            },
          ]);
          return false;
        }
        if (!/[#?!@$%^&*-]/.test(userInfo.password)) {
          form.setFields([
            {
              name: "password",
              errors: ["password should cantain atleast one special caracter #?!@$%^&*-"],
            },
          ]);
          return false;
        }

        setLoading(true);
        setError("");
        userRegister(
          userInfo,
          (r) => {
            setLoading(false);
            authenticate(r.token, () => {
              dispatch({ type: actionTypes.SET_USER, payload: r.user });
              history.push("/ocr-process");
              ocrDispatch({ type: actionTypes.OCR_CLEAR_STATE, payload: {} });
            });
          },
          (err) => {
            setLoading(false);
            setError(err);
            forgotform.resetFields(["username", "emil", "password", "confirmPassword"]);
          }
        );
      })
      .catch((e) => {
        console.log("validate Error on login modal");
      });
  };

  const onBackToLogin = () => {
    setForgotPassword(false);
    setRegister(false);
    setLoading(false);
    setError("");
    forgotform.resetFields(["email"]);
  };

  const onClickForgot = () => {
    setLoading(false);
    setRegister(false);
    setError("");
    form.resetFields(["password"]);
    setForgotPassword(true);
  };

  const onClickRegister = () => {
    setForgotPassword(false);
    setError("");
    setRegister(true);
    form.resetFields(["email", "password"]);
  };

  const onClickSignIn = () => {
    setForgotPassword(false);
    setRegister(false);
    setError("");
    form.resetFields(["email", "password"]);
  };

  return (
    <div className='login-modal-wrapper'>
      <Row justify='center' align='start' style={{ height: "100%" }}>
        <Col md={12} sm={24} className='left-content'>
          <img src={ExtractionImg} height='250px' width='100%' alt='Extract Image' />
          <h1>{pageContent[lang].title}</h1>
          <p>{pageContent[lang].desc}</p>
        </Col>
        {isForgotPassword && (
          <Col md={12} sm={24} className='right-content forgot-ps-container'>
            {isError && <Alert message={isError} type='error' showIcon={false} closable />}
            {isMsg && <Alert message={isMsg} type='success' showIcon={false} closable />}

            <h1>Forgot your password?</h1>
            <p>Enter your email below, and we'll send you the Rest Link</p>
            <Form
              form={forgotform}
              name='forgot-ps-form'
              layout='vertical'
              initialValues={{ email: form.getFieldValue("email") }}
              requiredMark={false}
              className='login-form-wrapper'
              onFinish={onForgotPs}>
              <Form.Item
                name='email'
                rules={[
                  {
                    type: "email",
                    message: "This email is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "email is required!",
                  },
                ]}>
                <Input placeholder='Email' />
              </Form.Item>
              <Form.Item noStyle>
                <Button loading={loading} type='primary' htmlType='submit' className='custom-btn login-btn'>
                  Send Rest Link
                </Button>
              </Form.Item>
            </Form>
            <div className='back-to-login' onClick={onBackToLogin}>
              Back to Login
            </div>
          </Col>
        )}

        {!isResiter && (
          <Col md={12} sm={24} className='right-content'>
            {isError && <Alert message={isError} type='error' showIcon={false} closable />}
            <h1>Sign In</h1>
            <Form
              form={form}
              name='login-form'
              layout='vertical'
              initialValues={{}}
              requiredMark={false}
              className='login-form-wrapper'
              onFinish={onSignIn}>
              <Form.Item
                name='email'
                rules={[
                  {
                    type: "email",
                    message: "this email is not valid email!",
                  },
                  {
                    required: true,
                    message: "email is required!",
                  },
                ]}>
                <Input placeholder='Email' />
              </Form.Item>
              <Form.Item name='password' rules={[{ required: true, message: "password is required!" }]}>
                <Input.Password placeholder='Password' />
              </Form.Item>
              <Form.Item noStyle>
                <Button loading={loading} type='primary' htmlType='submit' className='custom-btn login-btn'>
                  Sign In
                </Button>
              </Form.Item>
            </Form>
            <div className='regiter-wrapper'>
              <div className='forgot-password' onClick={onClickForgot}>
                Forgot Password?
              </div>
              <div className='click-to-register'>
                Don't have an account? <span onClick={onClickRegister}> Register</span>
              </div>
            </div>

            <Divider>or</Divider>
            <SocialLoign onSocialSignIn={onSignIn} />
          </Col>
        )}
        {isResiter && (
          <Col md={12} sm={24} className='right-content'>
            {isError && <Alert message={isError} style={{ marginBottom: "10px" }} type='error' closable />}
            <h1>Register</h1>
            <Form
              form={form}
              name='login-form'
              layout='vertical'
              initialValues={{}}
              requiredMark={false}
              className='login-form-wrapper'
              onFinish={onRegister}>
              <Form.Item name='username' rules={[{ required: true, message: "username is required!", min: 5 }]}>
                <Input placeholder='Username' />
              </Form.Item>
              <Form.Item
                name='email'
                rules={[
                  {
                    type: "email",
                    message: "this email is not valid email!",
                  },
                  {
                    required: true,
                    message: "email is required!",
                  },
                ]}>
                <Input placeholder='Email' />
              </Form.Item>
              <Form.Item name='password' rules={[{ required: true, message: "password is required!", min: 6 }]}>
                <Input.Password placeholder='Password' />
              </Form.Item>
              <Form.Item
                name='confirmPassword'
                dependencies={["password"]}
                rules={[
                  { required: true, message: "confirm password is required!", min: 6 },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("The password that you entered do not match!"));
                    },
                  }),
                ]}>
                <Input.Password placeholder='Confirm Password' />
              </Form.Item>
              <Form.Item noStyle>
                <Button loading={loading} type='primary' htmlType='submit' className='custom-btn login-btn'>
                  Register
                </Button>
              </Form.Item>
            </Form>

            <Divider>or</Divider>
            <div className='click-to-sign'>
              Already have account? <span onClick={onClickSignIn}> Sign In</span>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default LoginModal;
