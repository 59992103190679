import React from "react";
import Layout from "../../component/Layout/Layout";
import { Menu, Button, Tabs, message } from "antd";
import "./ToolsAndApi.scss";
import { Link, useHistory } from "react-router-dom";
import { CopyBlock } from "react-code-blocks";
import { isAuthenticated } from "../../services/auth";
import CustomModal from "../../component/CustomModal/CustomModal";
import LoginModal from "../LoginModal/LoginModal";
import { nodeJsSampleColde } from "../../services/pageContent/nodeJsSampleCode";
import { generateAPIKey, getAPIKeyByUserId } from "../../services/generateApiKey";

import jwt_decode from "jwt-decode";
import { useGlobalState } from "../../context/context";

const { TabPane } = Tabs;

const pythonSampleColde = `
# Requires "requests" to be installed (see python-requests.org)
import requests

response = requests.post(
    'https://api.alazka.ai/v1.0/alazka',
    files={'image_file': open('/path/to/file.jpg', 'rb')},
    data={'size': 'auto'},
    headers={'X-Api-Key': 'INSERT_YOUR_API_KEY_HERE'},
)
if response.status_code == requests.codes.ok:
    with open('no-bg.png', 'wb') as out:
        out.write(response.content)
else:
    print("Error:", response.status_code, response.text)

`;

const ToolsAndApi = () => {
  const [{ user_info }, dispatch] = useGlobalState();
  const history = useHistory();
  const isAuth = isAuthenticated();
  const [loading, setLoading] = React.useState(false);
  const [selectedTopic, setSelectedTopi] = React.useState("text-extraction");
  const [isLoginModal, setLoginModal] = React.useState(false);
  const closeModal = () => {
    setLoginModal(false);
  };
  const onSelectTopic = (e) => {
    setSelectedTopi(e.key);
    window.scrollTo({
      top: 400,
      behavior: "smooth",
    });
  };

  const ongenerateAPIKey = () => {
    if (!user_info?.name) {
      setLoginModal(true);
    } else {
      setLoading(true);
      getAPIKeyByUserId(
        user_info?.user_id,
        (r) => {
          if (r.apiKey) return history.push({ pathname: "/ocr-process", state: { menuName: "account-setting", id: "5" } });
        },
        (err) => {
          if (!err.isCreated) {
            generateAPIKey(
              user_info?.user_id,
              (r) => {
                setLoading(false);
                message.success(r.msg);
                history.push({ pathname: "/ocr-process", state: { menuName: "account-setting", id: "5" } });
              },
              (err) => {
                setLoading(false);
                message.error(err);
              }
            );
          } else {
            message.error(err);
          }
        }
      );
    }
  };
  return (
    <Layout faqs={undefined}>
      <div className='tools-api-wrapper'>
        <div className='side-bar-topics'>
          <div className='side-bar-title'>OCR API</div>
          <Menu onClick={onSelectTopic} className='topic-menus' cla selectedKeys={[selectedTopic]} mode='inline'>
            <Menu.Item key='text-extraction'>
              <a href='#text-extraction'>Text extraction</a>
            </Menu.Item>
            <Menu.Item key='easy-integrate'>
              <a href='#easy-integrate'> Easy to integrate</a>
            </Menu.Item>
            <Menu.Item key='get-started'>
              <a href='#get-started'> Get Started</a>
            </Menu.Item>
            <Menu.Item key='sample-code'>
              <a href='#sample-code'>Sample Code</a>
            </Menu.Item>
          </Menu>
        </div>
        <div className='topic-content-wrapper'>
          <div className='topic-content'>
            <div className='side-bar-title'>OCR API</div>
            <section id='text-extraction'>
              <div className='topic-content-left'>
                <h1>Extract Text automatically with 1 API call</h1>
                <p className='desc'>Explore our API documentation and examples to integrate alazka.ai into your application or workflow.</p>
                <Button type='primary' loading={loading} className='generate-api-key-btn' onClick={ongenerateAPIKey}>
                  Get API Key
                </Button>
                {/* <div className='lead'>Not a developer?</div>
                <p className='hint-link'>
                  There are plenty ready to use <Link style={{ color: "" }}>Tools & Apps</Link> by alazka.ocr and our community.
                </p> */}
              </div>
              <div className='topic-content-right'></div>
            </section>
            <section id='easy-integrate'>
              <div className='topic-content-left'>
                <h1>Easy to integrate</h1>
                <p className='desc'>Our API is a simple HTTP interface with various options:</p>
                <h3>Source images:</h3>
                <p className='desc-second'>Direct uploads</p>
                <h3>Result text:</h3>
                <p className='desc-second'>Detected text on Image and Download file in plain text format</p>
                <h3>Output resolution:</h3>
                <p className='desc-second'>up to 25 megapixels</p>
                {/* <p className='hint-link'>
                  There are plenty ready to use <Link style={{ color: "" }}>Tools & Apps</Link> by remove.bg and our community.
                </p> */}
              </div>
              <div className='topic-content-right'></div>
            </section>
            <section id='get-started'>
              <div className='topic-content-left'>
                <h1>Get started</h1>
                <ul>
                  <li>
                    <span style={{ color: "#39a2ff", cursor: "pointer" }} onClick={ongenerateAPIKey}>
                      Get your API Key.
                    </span>
                    <br />
                    {/* Your first 50 API calls per month are on us <Link to='/pricing'>(see Pricing)</Link>. temprory closed */}
                  </li>
                  <li>Use the following code samples to get started quickly</li>
                  <li>Review the reference docs to adjust any parameters</li>
                </ul>

                {/* <p className='hint-link'>
                  There are plenty ready to use <Link style={{ color: "" }}>Tools & Apps</Link> by remove.bg and our community.
                </p> */}
              </div>
              <div className='topic-content-right'></div>
            </section>
            <section id='sample-code'>
              <div className='topic-content-left' style={{ flexBasis: "100%" }}>
                <h1>Sample Code</h1>

                <Tabs className='sample-code-tabs'>
                  <TabPane tab='Python' key='1'>
                    <CopyBlock text={pythonSampleColde} theme={"dracula"} language={"python"} showLineNumbers={true} wrapLines />
                  </TabPane>
                  <TabPane tab='Node.js' key='2'>
                    <CopyBlock text={nodeJsSampleColde} theme={"dracula"} language={"javascript"} showLineNumbers={true} wrapLines />
                  </TabPane>
                </Tabs>

                {/* <p className='hint-link'>
                  There are plenty ready to use <Link style={{ color: "" }}>Tools & Apps</Link> by remove.bg and our community.
                </p> */}
              </div>
            </section>
          </div>
        </div>
      </div>

      <CustomModal isModalVisible={isLoginModal} handleCancel={closeModal} borderRadius height={550}>
        <LoginModal closeSignInModal={closeModal} resterModel={false} />
      </CustomModal>
    </Layout>
  );
};

export default ToolsAndApi;
