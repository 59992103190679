import { getLocalStorage, msgModal } from "../component/utils/utils";
import { main_api_instance } from "./webapi";
const webApi = main_api_instance;

export const uploadProcessFile = async (fileInfo, onSuccess, onFailure) => {
  try {
    const res = await webApi.post(
      "/process/file/upload",
      {
        user_id: fileInfo.user_id,
        file_uid: fileInfo.file_uid,
        name: fileInfo.name,
        size: fileInfo.size,
        type: fileInfo.type,
        success: fileInfo.success,
        role: fileInfo.role,
        noOf_pages: fileInfo.noOf_pages,
        extractionResult: fileInfo.extractionResult,
      },
      {
        headers: {
          Authorization: `${getLocalStorage("jwt")}`,
        },
      }
    );
    if (res.status === 200) {
      // console.log("success upload file ", res);
      onSuccess();
    }
    onFailure();
  } catch (err) {
    onFailure();
  }
};
export const getAllProcessFile = async (pageNo, pageSize, id, onSuccess, onFailure) => {
  try {
    const res = await webApi.get(`/process/file/get-all/${id}?pageno=${pageNo}&pageSize=${pageSize}`);
    if (res.status === 200 && res.data.success) {
      return onSuccess(res.data);
    } else {
      onFailure(res.data.msg);
    }
  } catch (err) {
    onFailure("No process files found");
  }
};
export const deleteProcessFile = async (id, fileId, onSuccess, onFailure) => {
  try {
    const res = await webApi.delete(`/process/file/delete/${id}/${fileId}`);
    if (res.status === 200) {
      return onSuccess(res.data.msg);
    } else {
      onFailure(res.data.msg);
    }
  } catch (err) {
    onFailure("failed to delete a file");
  }
};
