export function pageNumberList(spec, n) {
  spec = spec.replace(/\s+/g, "");
  spec = spec.replace(/–/g, "-");
  spec = spec.replace(/-+/g, "-");
  const ranges = spec.split(",");
  const newOrder = [];
  for (let range of ranges) {
    // if (range == '0') range = `${n+1}`;
    if (!range.includes("-")) range = `${range}-${range}`;
    let [before, after] = range.split("-");
    if (!before) before = 1;
    if (!after) after = n;
    before = Number(before);
    after = Number(after);
    const sign = before <= after ? 1 : -1;
    for (let i = before; ; i += sign) {
      newOrder.push(i > 0 ? i - 1 : n);
      if (i == after) break;
    }
  }
  // console.log(`For spec ${spec}, new order is [${newOrder}].`);
  return newOrder;
}

export const getImageDimensions = (file) => {
  return new Promise(function (resolved, rejected) {
    var i = new Image();
    i.onload = function () {
      resolved({ w: i.width, h: i.height });
    };
    i.src = file;
  });
};
