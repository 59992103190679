import webApi from "./webapi";
import axios from "axios";
import { getExtension, msgModal } from "../component/utils/utils";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { Document, Page, pdfjs } from "react-pdf";
import { pageNumberList } from "./utils/ocr_utils";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CancelToken = axios.CancelToken;

export let cancelReq = {};

const readFileData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

async function newPdfBytes(pdfDoc, spec) {
  const pages = pdfDoc.getPages();
  const n = pages.length;
  // Dimensions of blank pages: use the second page if possible.
  const { width, height } = pages[n > 1 ? 1 : 0].getSize();
  pdfDoc.addPage([width, height]); // A blank page at the end

  const newOrder = pageNumberList(spec, n);
  // Use the new order.
  const newDoc = await PDFDocument.create();

  const newPages = await newDoc.copyPages(pdfDoc, newOrder);
  for (let newPage of newPages) newDoc.addPage(newPage);

  const newBytes = await newDoc.save();
  // console.log(`Returning ${newBytes.byteLength} bytes`);
  return { newBytes, newOrder };
}

//param: file -> the input file (e.g. event.target.files[0])
//return: images -> an array of images encoded in base64
export const convertPdfToImages = (file) => {
  return new Promise(async (resolved, rejected) => {
    const images = [];
    const data = await readFileData(file);
    const pdf = await pdfjs.getDocument(data).promise;
    const canvas = document.createElement("canvas");
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1.5 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      images.push(canvas.toDataURL());
    }
    canvas.remove();
    resolved(images);
  });
};

export const getPdfPromiseResult = (fileToUpload, url, file, pdfPageOrder) => {
  return new Promise(async (resolved, rejected) => {
    const pdfImgResult = [];

    const pdfImgProcess = fileToUpload.map(async (pdfImg, i) => {
      const imgFile = dataURLtoFile(pdfImg, file.name);
      const formData = new FormData();
      formData.append("file", imgFile);
      await webApi
        .post(url, formData, {
          cancelToken: new CancelToken(function executor(c) {
            cancelReq[`${file.uid}-${i}`] = c;
          }),
        })
        .then((res) => {
          pdfImgResult.push({ ...res.data, pageNo: pdfPageOrder[i] + 1 });
        })
        .catch((err) => {
          rejected(err);
        });
    });

    await Promise.all(pdfImgProcess)
      .then((res) => {
        resolved(pdfImgResult);
      })
      .catch((err) => {
        rejected(err);
      });
  });
};

export const getOCRResult = async (freeOCR, file, downPer) => {
  try {
    let fileForProcesssing = file.fileToUploadPro;
    let totalPage = 1;
    let pdfPageOrder = [];
    let bytesLength = file.size;
    if (file.type === "pdf") {
      const pagesToPro = file.selectedPdfPage;
      const pdfDoc = await PDFDocument.load(file.fileInb64);
      const pdfBytes = await newPdfBytes(pdfDoc, pagesToPro);
      totalPage = pdfBytes.newOrder.length;
      bytesLength = pdfBytes.newBytes.byteLength;
      pdfPageOrder = pdfBytes.newOrder;
      const outputBlob = new Blob([pdfBytes.newBytes]);
      fileForProcesssing = await convertPdfToImages(outputBlob);
    }

    let encoding_type = file.type === "pdf" ? "png" : file.type;
    let doc_type = "image";

    let fileToUpload = fileForProcesssing;

    let url = `${process.env.REACT_APP_API_OCR_ENDPOINT}/?remove_noise=${file.removeNoise}&upscale=${file.enhanceImgQlty}&auto_align=${file.correctOrient}&encoding_type=${encoding_type}&doc_type=${doc_type}`;

    if (file.type === "pdf") {
      try {
        const res = await getPdfPromiseResult(fileToUpload, url, file, pdfPageOrder);
        const allfalied = res.filter((file, i) => file.success === false);
        if (allfalied?.length === res?.length) {
          return {
            data: [],
            success: true,
            isTexDetectd: false,
            message: "text not detected",
            totalPage: totalPage,
            bytesLength: bytesLength,
          };
        } else {
          return {
            success: true,
            data: res.sort((a, b) => a?.pageNo - b?.pageNo),
            totalPage: totalPage,
            bytesLength: bytesLength,
            isTexDetectd: true,
          };
        }
      } catch (err) {
        return { ...err, success: false };
      }
    } else {
      const formData = new FormData();
      formData.set("file", fileToUpload);
      const res = await webApi.post(url, formData, {
        cancelToken: new CancelToken(function executor(c) {
          cancelReq[file.uid] = c;
        }),
        onDownloadProgress: (progressEvent) => {
          const total = parseFloat(progressEvent.total);
          const current = progressEvent.loaded;
          let percentCompleted = Math.floor((current / total) * 100);
          downPer(percentCompleted, file.uid);
        },
      });
      if (res.status === 200 && res.data.success) {
        return { data: [{ ...res.data, pageNo: 1 }], success: true, totalPage: totalPage, bytesLength: bytesLength, isTexDetectd: true };
      } else if (!res.data.success) {
        return {
          data: [],
          success: true,
          isTexDetectd: false,
          message: "text not detected",
          totalPage: totalPage,
          bytesLength: bytesLength,
        };
      }
    }
  } catch (err) {
    return { ...err, success: false };
  }
};
