import React from "react";
import { Upload, Row, Col, message, Space, Button } from "antd";
import { useHistory } from "react-router-dom";
import UploadIcon from "../../assest/cloud-upload.svg";
import { getExtension, msgModal, supportedFile, fileSizeLimit } from "../utils/utils";
import actionTypes from "../../context/actions";
import { UploadOutlined } from "@ant-design/icons";
import { useGlobalState, useOCRState } from "../../context/context";
import "./FileUploadContainer.scss";
import { PDFDocument } from "pdf-lib";
import { v4 as uuidv4 } from "uuid";

const { Dragger } = Upload;

const delay = (interval) => new Promise((resolve) => setTimeout(resolve, interval));
export const fileLimit = 25;

const FileUploadContainer = ({ freeOCR, multiple }) => {
  const history = useHistory();
  const [globalState, dispatch] = useGlobalState();
  const [{ filesToProccess, correctOrient, enhanceImgQlty, fileFormat, removeNoise }, ocrDispatch] = useOCRState();

  const onFileUpload = async (file) => {
    if (multiple) {
      dispatch({ type: actionTypes.LOADING, payload: {} });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async (e) => {
        let pages = 1;
        if (getExtension(file.type) === "pdf") {
          const pdfDoc = await PDFDocument.load(reader.result);
          pages = pdfDoc.getPageCount();
        }
        // if (pages > globalState.user_info?.leftCredit && globalState.user_info?.role === "user") return msgModal("info", "Unable to upload file, You don't have enough Credit Left!", "Oops!");
        ocrDispatch({
          type: actionTypes.FILES_TO_PROCESS_PAID,
          payload: {
            pdfFileView: URL.createObjectURL(file),
            fileInb64: reader.result,

            selectedPdfPage: `1-${pages}`,
            pages: pages,

            uid: uuidv4() + file.name,
            name: file.name,
            size: file.size,
            type: getExtension(file.type),

            correctOrient: correctOrient,
            enhanceImgQlty: enhanceImgQlty,
            removeNoise: removeNoise,

            fileFormat: fileFormat,
            downPer: 0,

            isSuccess: false,
            isProcessed: false,
            isErrMsg: "",
            isError: false,
            status: "ready",
            resTime: null,

            fileToUploadPro: file,
            extractionResult: null,
          },
        });
        dispatch({ type: actionTypes.LOADING, payload: {} });
      };
    } else {
      dispatch({ type: actionTypes.LOADING, payload: {} });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async (e) => {
        let pages = 1;
        if (getExtension(file.type) === "pdf") {
          const pdfDoc = await PDFDocument.load(reader.result);
          pages = pdfDoc.getPageCount();
        }
        ocrDispatch({
          type: actionTypes.FILES_TO_PROCESS_FREE,
          payload: {
            pdfFileView: URL.createObjectURL(file),
            fileInb64: reader.result,

            selectedPdfPage: `1-${pages}`,
            pages: pages,

            uid: file.uid,
            name: file.name,
            size: file.size,
            type: getExtension(file.type),

            correctOrient: correctOrient,
            enhanceImgQlty: enhanceImgQlty,
            removeNoise: removeNoise,

            fileFormat: fileFormat,
            downPer: 0,

            isSuccess: false,
            isErrMsg: "",
            isError: false,
            status: "ready",
            resTime: null,
            isProcessed: false,

            fileToUploadPro: file,
            extractionResult: null,
          },
        });
        history.push("/upload");
        dispatch({ type: actionTypes.LOADING, payload: {} });
      };
    }
  };

  const onChange = async (file, fileList) => {
    const added_no_of_Page = filesToProccess.reduce((total, x) => {
      if (!x.isSuccess) {
        return total + x.pages;
      }
    }, 0);

    if (
      (globalState.user_info?.leftCredit === 0 || globalState.user_info?.leftCredit <= Number(added_no_of_Page)) &&
      globalState.user_info?.role === "user"
    ) {
      return msgModal("info", "Unable to upload file, You don't have enough Credit Left!", "Oops!");
    }

    if (!supportedFile.includes(getExtension(file.type))) return msgModal("info", "Sorry file not supported!", "Info");

    if (Number(filesToProccess.length) > Number(fileLimit)) {
      message.info(`You can't upload more than ${fileLimit} files`, 5);
      return false;
    }

    const isFileUploaded = filesToProccess.find((f) => f.name === file.name);
    if (multiple && isFileUploaded) {
      return message.warn(`${file.name} , file already uploaded!`);
    }
    if (file.size <= fileSizeLimit(globalState.user_info?.plan)) {
      //fileSize should be less than 10MB
      onFileUpload(file);
      return false;
    } else {
      message.info("File size should not more than 10 MB", 5);
      return false;
    }
  };

  return (
    <Row align='center' gutter={[0, 24]}>
      <Col md={15} sm={24}>
        <Dragger
          name='file'
          multiple={multiple}
          max={fileLimit}
          showUploadList={false}
          maxCount={fileLimit}
          accept='.jpeg,.pdf,.jpg,.png'
          disabled={globalState.isProcessing || globalState.user_info?.leftCredit === 0}
          beforeUpload={(file) => false}
          onChange={(e) => {
            onChange(e.file, e.fileList);
          }}
          className={freeOCR ? "dummy-upload-container" : "upload-file-container"}>
          {freeOCR ? (
            <div className='upload-dummy-box'>
              <Space size='middle' direction='vertical' align='center'>
                <div className='upload-drag-icon'>
                  <img src={UploadIcon} alt='Upload' />
                </div>
                <Button size='large' className='browse-img-btn'>
                  <UploadOutlined style={{ fontSize: "1.5rem" }} />
                  Upload Image
                </Button>
                <p className='upload-text'>
                  Or <span className='highlite-orange'>Drop a file</span>
                </p>
                <p className='upload-hint'>Support .png, .jpeg, .jpg, .pdf</p>
              </Space>
            </div>
          ) : (
            <Row align='middle' justify='start' className='app-upload-box-content'>
              <Col md={3} sm={24}>
                <div className='upload-drag-icon'>
                  <img src={UploadIcon} alt='Upload' />
                </div>
              </Col>
              <Col md={18} sm={24}>
                <p className='upload-text'>
                  <span className='highlite-blue'>Upload Image</span> or Drop a file
                </p>
                <p className='upload-hint'>Support .png, .jpeg, .jpg, .pdf</p>
                {/* <p className='upload-hint'>up to 5MB</p> */}
              </Col>
            </Row>
          )}
        </Dragger>
      </Col>
    </Row>
  );
};

export default FileUploadContainer;
