import { getLocalStorage } from "../component/utils/utils";
import { main_api_instance } from "./webapi";
const webApi = main_api_instance;

export const getAllUsers = async (onSuccess, onFailure) => {
  try {
    const res = await webApi.get(`/user/get-all-users`, {
      headers: {
        Authorization: `${getLocalStorage("jwt")}`,
      },
    });
    if (res.status === 200 && res.data.success) {
      return onSuccess(res.data?.users);
    } else {
      onFailure(res.data.msg);
    }
  } catch (err) {
    onFailure("Something wents wrong User not found");
  }
};

export const onAdminUpdateUser = async (userInfo, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/user/admin-update-user", userInfo);
    if ((res.status === 200, res.data.success)) {
      onSuccess(res.data);
    }
  } catch (err) {
    onFailure("Something wrong User not updated");
  }
};
