import React from "react";
import { Carousel } from "antd";
import BannerImg1 from "../../assest/bannerImg/bannerImg1.png";
import BannerImg2 from "../../assest/bannerImg/bannerImg2.png";
import BannerImg3 from "../../assest/bannerImg/bannerImg3.jpeg";
const BannerCarousel = () => {
  return (
    <Carousel effect='fade' className='hero-carousel' autoplay={true} dots={false}>
      <div className='banner-img'>
        <img src={BannerImg1} alt='Banner Image' />
      </div>
      <div className='banner-img'>
        <img src={BannerImg2} alt='Banner Image' />
      </div>
      <div className='banner-img'>
        <img src={BannerImg3} alt='Banner Image' />
      </div>
    </Carousel>
  );
};

export default BannerCarousel;
