import React from "react";
import { Row, Col, Space, Button, Dropdown, Menu, Divider, Drawer } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import AlazkaLogo from "../../assest/alazka-logo.png";
import CustomModal from "../CustomModal/CustomModal";
import LoginModal from "../../Page/LoginModal/LoginModal";
import { UserOutlined, CaretDownOutlined, MenuOutlined, HistoryOutlined, LogoutOutlined } from "@ant-design/icons";
import "./Navbar.scss";
import { isAuthenticated, Logout } from "../../services/auth";
import { useGlobalState, useOCRState } from "../../context/context";
import actionTypes from "../../context/actions";
import UserAvatar from "../UserAvatar/UserAvatar";
import navtext from "../../services/pageContent/NavBar.json";

import { truncateString } from "../utils/utils";

const Navbar = ({ onChangeView = () => {} }) => {
  const [{ user_info, lang }, dispatch] = useGlobalState();
  const [{ filesToProccess }, ocrDispatch] = useOCRState();

  const isAuth = isAuthenticated();
  const [navbarFixed, setNavbarFixed] = React.useState(false);
  const [navContent, setNavContent] = React.useState(navtext[lang]);
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [isLoginModal, setLoginModal] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const history = useHistory();
  const location = useLocation();
  const closeModal = () => {
    setLoginModal(false);
  };
  const openLoginModal = () => {
    setLoginModal(true);
    onCloseMenu();
  };
  const onLogout = () => {
    dispatch({ type: actionTypes.LOADING, payload: {} });
    Logout(
      (r) => {
        // message.success(r);
        dispatch({ type: actionTypes.LOADING, payload: {} });
        dispatch({ type: actionTypes.SET_USER, payload: null });
        ocrDispatch({ type: actionTypes.OCR_CLEAR_STATE, payload: {} });
        history.push("/");
      },
      (err) => {
        // message.error(err);
      }
    );
  };

  const getActiveNavLink = (navlink, pathName) => {
    if (navlink === pathName) {
      return "active-link";
    } else {
      return "";
    }
  };

  React.useEffect(async () => {
    setNavContent(navtext[lang]);
    if (user_info) {
      setUser({ ...user_info });
    }
  }, [user_info, lang]);

  const onOpenMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  const onCloseMenu = () => {
    setMobileMenu(false);
  };

  React.useEffect(() => {
    if (location.state?.loginModal) {
      openLoginModal();
    }
  }, [location.state?.loginModal]);

  const menu = (
    <Menu>
      <Menu.Item
        key='0'
        icon={<UserOutlined />}
        onClick={() => {
          history.push({ pathname: "/account", state: { id: "3" } });
        }}>
        {navContent.userMenu.myAccount}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='3'
        icon={<HistoryOutlined />}
        onClick={() => {
          history.push({ pathname: "/account", state: { id: "3" } });
        }}>
        {navContent.userMenu.conversionHistory}
      </Menu.Item>
      <Menu.Divider />
      {/* <Menu.Item  //temprory closed
        key='4'
        icon={<WalletOutlined />}
        onClick={() => {
          history.push("/upgrade-plan");
        }}>
        {navContent.userMenu.buyCreditUpgradePlan}
      </Menu.Item>
      <Menu.Divider /> */}

      <Menu.Item key='5' icon={<LogoutOutlined />} onClick={onLogout}>
        {navContent.navLink.logout}
      </Menu.Item>
    </Menu>
  );
  return (
    <div id='mob-nav-link'>
      <Row id='main-header' className={`main-header ${"fixed-header"}`} justify='space-between' align='middle'>
        <Col span={8}>
          <Space align='center' className='app-title-mobile'>
            <div
              className='app-logo'
              onClick={() => {
                if (user?.name) {
                  history.push("/ocr-process");
                } else {
                  history.push("/");
                }
              }}>
              <img src={AlazkaLogo} alt='Alazka' />
            </div>
            {navContent.appTagLine && (
              <>
                <div className='vt-line'>|</div>
                <div className='app-tag-line'>{navContent.appTagLine}</div>
              </>
            )}
          </Space>
        </Col>
        <Col span={16} className='desktop-btns' align='end'>
          <Space size='large' style={{ float: "right" }}>
            <div
              className={`d-nav-link ${getActiveNavLink("/tools-and-api", location.pathname)}`}
              onClick={() => {
                history.push("/tools-and-api");
              }}>
              {navContent.navLink.toolsAndApi}
            </div>
            {/* {isAuth && (   //temprory closed
              <div
                className={`d-nav-link ${getActiveNavLink("/upgrade-plan", location.pathname)}`}
                onClick={() => {
                  history.push("/upgrade-plan");
                }}>
                {navContent.navLink.upgradePlan}
              </div>
            )} */}
            {!isAuth && (
              <>
                <div
                  className={`d-nav-link ${getActiveNavLink("/how-to-use", location.pathname)}`}
                  onClick={() => history.push("/how-to-use")}>
                  {navContent.navLink.howToUse}
                </div>
                {/* <div className={`d-nav-link ${getActiveNavLink("/pricing", location.pathname)}`} onClick={() => history.push("/pricing")}>  //temprory closed
                  {navContent.navLink.pricing}
                </div> */}
              </>
            )}
            {!isAuth && (
              <>
                <Button
                  size='large'
                  className='extract-btn header-btn'
                  onClick={() => {
                    if (user?.name) {
                      history.push("/ocr-process");
                    } else {
                      history.push("/upload");
                    }
                  }}>
                  {navContent.navLink.ocr}
                </Button>

                <Button size='large' onClick={openLoginModal} className='login-btn header-btn'>
                  {navContent.navLink.signin}
                </Button>
              </>
            )}

            {user_info?.name && <Col style={{ padding: "0 0", color: "#707070" }}>|</Col>}
            {user_info?.name && (
              <Dropdown placement='bottomRight' overlay={menu} trigger={["click"]} overlayClassName='user-dropdown-menu'>
                <div className='user-dropdown-info ant-dropdown-link' onClick={(e) => e.preventDefault()}>
                  <Space>
                    <Space direction='vertical' size={0}>
                      {user?.name && (
                        <div className='user-name'>
                          {navContent.welcome} {truncateString(user?.name, 20)} <CaretDownOutlined />
                        </div>
                      )}
                      <div className='user-credit-info'>
                        {user?.role === "user" && (
                          <>
                            <span className='credit-logo'>c</span> {user?.leftCredit} of {user?.totalCredit} {navContent.creditLeft}
                          </>
                        )}
                        {user?.role === "admin" && (
                          <>
                            <span className='credit-logo'>c</span> {user?.leftCredit} {navContent.credit}
                          </>
                        )}
                      </div>
                    </Space>
                    <UserAvatar size={45} />
                  </Space>
                </div>
              </Dropdown>
            )}
          </Space>
        </Col>
        <Col className='mobile-menu'>
          <div className='hamburger' onClick={onOpenMenu}>
            {user_info?.name ? (
              <Space size={5} style={{ marginRight: "-5px" }}>
                <span className='user-name'>{user_info.name}</span> <UserAvatar size={40} />
              </Space>
            ) : (
              <MenuOutlined style={{ fontSize: "28px" }} className='hamburger-icon' />
            )}
          </div>
        </Col>

        {/* login modal */}
        <CustomModal isModalVisible={isLoginModal} handleCancel={closeModal} borderRadius height={550}>
          <LoginModal closeSignInModal={closeModal} />
        </CustomModal>
      </Row>

      {/* For Mobile navbar */}
      <Drawer
        title={
          <div className='app-logo' onClick={() => (user?.name ? onChangeView("upload-view") : history.push("/"))}>
            <img src={AlazkaLogo} alt='Alazka' />
          </div>
        }
        placement='top'
        width='100%'
        height={isAuth ? 370 : 290}
        onClose={onCloseMenu}
        visible={mobileMenu}
        getContainer={() => document.getElementById("mob-nav-link")}
        className='mobile-menu-container'
        extra={
          <Space>
            <Button onClick={onCloseMenu}>X</Button>
          </Space>
        }>
        <Row justify='end' gutter={[0, 12]} align='middle'>
          <Col span={24}>
            <Button
              type='text'
              className={`nav-link ${getActiveNavLink("/upload", location.pathname)}`}
              onClick={() => {
                if (user?.name) {
                  history.push("/ocr-process");
                  onCloseMenu();
                } else {
                  history.push("/upload");
                  onCloseMenu();
                }
              }}>
              {navContent.navLink.ocr}
            </Button>
          </Col>
          {!isAuth && (
            <Col span={24}>
              <Button
                type='text'
                className={`nav-link ${getActiveNavLink("/how-to-use", location.pathname)}`}
                onClick={() => {
                  onCloseMenu();
                  history.push("/how-to-use");
                }}>
                {navContent.navLink.howToUse}
              </Button>
            </Col>
          )}

          {/* {!isAuth && (  //temprory closed
            <Col span={24}>
              <Button
                type='text'
                onClick={() => {
                  history.push("/pricing");
                  onCloseMenu();
                }}
                className={`nav-link`}>
                {navContent.navLink.pricing}
              </Button>
            </Col>
          )} */}
          <Col span={24}>
            <Button
              type='text'
              onClick={() => {
                history.push("/tools-and-api");
                onCloseMenu();
              }}
              className='nav-link'>
              {navContent.navLink.toolsAndApi}
            </Button>
          </Col>

          {isAuth && (
            <>
              <Col span={24}>
                <Button
                  type='text'
                  onClick={() => {
                    history.push({ pathname: "/account", state: { id: "3" } });
                    onCloseMenu();
                  }}
                  className={`nav-link`}>
                  {navContent.userMenu.myAccount}
                </Button>
              </Col>
              {/* <Col span={24}> //temprory closed
                <Button
                  type='text'
                  onClick={() => {
                    history.push("/upgrade-plan");
                    onCloseMenu();
                  }}
                  className={`nav-link`}>
                  {navContent.userMenu.buyCreditUpgradePlan}
                </Button>
              </Col> */}
              <Col span={24}>
                <Divider style={{ margin: 0 }} />
                <Space direction='vertical' size={0}>
                  <div className='user-name'>{user?.name}</div>
                  <div className='user-credit-info'>
                    {user?.role === "user" && (
                      <>
                        <span className='credit-logo'>c</span> {user?.leftCredit} of {user?.totalCredit} {navContent.creditLeft}
                      </>
                    )}
                    {user?.role === "admin" && (
                      <>
                        <span className='credit-logo'>c</span> {user?.leftCredit} {navContent.credit}
                      </>
                    )}
                  </div>
                </Space>
              </Col>
            </>
          )}
          {!isAuth && (
            <Col span={24}>
              <Divider style={{ margin: 0 }} />
              <Button size='large' onClick={openLoginModal} className='login-btn header-btn'>
                {navContent.navLink.signin}
              </Button>
            </Col>
          )}
          {isAuth && (
            <Col span={24}>
              <Divider style={{ margin: 0 }} />
              <Button size='large' onClick={onLogout} className='logout-btn header-btn'>
                {navContent.navLink.logout}
              </Button>
            </Col>
          )}
        </Row>
      </Drawer>
    </div>
  );
};

export default Navbar;
