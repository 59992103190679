import React from "react";
import UserAccount from "../../component/UserAccount/UserAccount";
import Layout from "../../component/Layout/Layout";
import LogedInFaqs from "../../services/pageContent/logedInFaqs.json";
import { useGlobalState, useOCRState } from "../../context/context";

const UserAccountPage = () => {
  const [{ user_info, lang }, dispatch] = useGlobalState();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout faqs={LogedInFaqs[lang]}>
      <UserAccount />
    </Layout>
  );
};

export default UserAccountPage;
