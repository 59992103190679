import actionTypes from "./actions";

export const initialState = {
  user_info: null,
  loading: false,
  isOffline: false,
  refresh: false,
  userAvatar: null,
  isProcessing: false,
  lang: "English",
  message: {
    msgType: "",
    msg: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user_info: { ...action.payload },
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case actionTypes.IS_OFFLINE:
      return {
        ...state,
        isOffline: action.payload,
      };
    case actionTypes.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case actionTypes.IS_PROCESSING:
      return {
        ...state,
        isProcessing: action.payload,
      };
    case actionTypes.LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case actionTypes.USER_AVATAR:
      return {
        ...state,
        userAvatar: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
