const actionTypes = {
  SET_USER: "SET_USER",
  LOADING: "LOADING",
  MESSAGE: "MESSAGE",
  IS_OFFLINE: "IS_OFFLINE",
  REFRESH: "REFRESH",
  IS_PROCESSING: "IS_PROCESSING",
  LANG: "LANG",
  USER_AVATAR: "USER_AVATAR",

  PROCESS_MODE: "PROCESS_MODE",
  FILES_TO_PROCESS_FREE: "FILES_TO_PROCESS_FREE",
  FILES_TO_PROCESS_PAID: "FILES_TO_PROCESS_PAID",
  OCR_CLEAR_STATE: "OCR_CLEAR_STATE",
  FILE_STATUS: "FILE_STATUS",
  DOWN_PER: "DOWN_PER",
  SET_EXTRACT_RESULT: "SET_EXTRACT_RESULT",
  OCR_REFRESH: "OCR_REFRESH",
  DELETE_UPLOAD_FILE: "DELETE_UPLOAD_FILE",
  SET_FILE_FORMAT: "SET_FILE_FORMAT",
  SET_ALL_FILEFORMAT: "SET_ALL_FILEFORMAT",
  ALL_PROCESS_MODE: "ALL_PROCESS_MODE",
  SELECT_PAGES: "SELECT_PAGES",
};

export default actionTypes;
