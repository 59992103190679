import React from "react";
import { Row, Col, Space, Button, Checkbox, Select, Tooltip, Divider, Input, Popconfirm, Alert } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { DeleteFilled, EyeOutlined, LeftOutlined, RightOutlined, CloseOutlined } from "@ant-design/icons/lib/icons";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const OverlayContainer = ({ file }) => {
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const incPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const decPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  return (
    <Row justify='center' align='middle'>
      <Col>
        {file.type === "pdf" ? (
          <Row className='pdf-view-modal' id='pdf-view' justify='center'>
            {numPages > 0 && (
              <Col span={22}>
                <Row align='middle' justify='center' gutter={[48, 0]}>
                  <Col>
                    <Button type='primary' icon={<LeftOutlined />} onClick={decPage} className='prv-btn' disabled={pageNumber === 1} />
                  </Col>
                  <Col>
                    {pageNumber} / {numPages}
                  </Col>
                  <Col>
                    <Button
                      type='primary'
                      icon={<RightOutlined />}
                      onClick={incPage}
                      className='next-btn'
                      disabled={pageNumber === numPages}
                    />
                  </Col>
                </Row>
                <Divider style={{ marginBottom: 0 }} />
              </Col>
            )}
            <Col span={22} className='pdf-view-container'>
              <Document
                file={file.pdfFileView}
                onLoadSuccess={onDocumentLoadSuccess}
                error={<Alert message='Error' description="Something wrong pdf can't read!" type='error' showIcon />}>
                <Page pageNumber={pageNumber} />
              </Document>
            </Col>
          </Row>
        ) : (
          <div className='pdf-view-container' style={{ maxHeight: 540, overflow: "auto" }}>
            <img src={file.fileInb64} width='100%' alt={file.name} />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default OverlayContainer;
