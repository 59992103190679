import React from "react";
import Layout from "../../component/Layout/Layout";
import { Steps, Divider, Row, Col } from "antd";
import "./HowToUse.scss";
import ChangeMode from "../../assest/gif/change-mode.gif";
import GetOcrText from "../../assest/gif/get-ocr-text.gif";
import OCRPageUpload from "../../assest/gif/ocr-page-upload.gif";
import HowToUseFaqs from "../../services/pageContent/howToUseFaqs.json";
import pageContent from "../../services/pageContent/howToUsePage.json";
import { useGlobalState } from "../../context/context";
const { Step } = Steps;

const HowToUse = () => {
  const [{ lang }, dispatch] = useGlobalState();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout faqs={HowToUseFaqs[lang]}>
      <div className='how-to-use-wrapper'>
        <div className='header'>
          <h1 className='title'>{pageContent[lang].title}</h1>
          <p className='desc'>{pageContent[lang].desc}</p>
        </div>
        <div className='use-demo-container'>
          <Steps current='11' status='wait' direction='vertical'>
            <Step
              title={pageContent[lang].step_1.title}
              status='wait'
              description={
                <Row gutter={[24, 0]} justify='space-between' className='step-row'>
                  <Col md={24} lg={8}>
                    <p>{pageContent[lang].step_1.desc}</p>
                  </Col>
                  <Col lg={15} md={24}>
                    <img src={OCRPageUpload} alt='upload page' width='100%' height='100%' />
                  </Col>
                </Row>
              }
            />
            <Step
              status='wait'
              title={pageContent[lang].step_2.title}
              description={
                <Row gutter={[24, 0]} justify='space-between'>
                  <Col md={24} lg={8}>
                    <p>{pageContent[lang].step_2.desc}</p>
                  </Col>
                  <Col lg={15} md={24}>
                    <img src={ChangeMode} alt='upload page' width='100%' />
                  </Col>
                </Row>
              }
            />
            <Step
              status='wait'
              title={pageContent[lang].step_3.title}
              description={
                <Row gutter={[48, 0]} justify='space-between'>
                  <Col md={24} lg={8}>
                    <p>{pageContent[lang].step_3.desc}</p>
                  </Col>
                  <Col lg={15} md={24}>
                    <img src={GetOcrText} alt='upload page' width='100%' />
                  </Col>
                </Row>
              }
            />
          </Steps>
        </div>
      </div>
      <Divider />
    </Layout>
  );
};

export default HowToUse;
