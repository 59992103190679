import React from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../component/Layout/Layout";
import "./FreeOCR.scss";
import FileUploadContainer from "../../component/FileUploadContainer/FileUploadContainer";
import FileProcessingContainer from "../../component/FileProcessingContainer/FileProcessingContainer";

import { isAuthenticated } from "../../services/auth";
import pageContent from "../../services/pageContent/HeroBanner.json";
import HomePagefaqs from "../../services/pageContent/homePageFaq.json";
import { useGlobalState, useOCRState } from "../../context/context";

const FreeOCR = () => {
  const [{ lang }, dispatch] = useGlobalState();
  const [{ filesToProccess }, ocrDispatch] = useOCRState();

  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (isAuthenticated()) {
      history.push("/ocr-process");
    }
  }, []);
  return (
    <Layout faqs={HomePagefaqs[lang]}>
      <div className='free-ocr-wrapper' id='#ocr-process'>
        <h2 className='app-title'>
          {pageContent[lang].title[0]} {pageContent[lang].title[1]}
        </h2>
        <p className='app-desc'>{pageContent[lang].subTitle}</p>
        <div style={{ marginBottom: "3rem" }}>
          <FileUploadContainer multiple={false} />
        </div>
        {filesToProccess.length > 0 && (
          <div style={{ marginBottom: "4rem" }}>
            <FileProcessingContainer freeOCR={true} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default FreeOCR;
