import React from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router-dom";

const PageNotFound = () => {
  const history = useHistory();
  return (
    <div className='page-not-found'>
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={
          <Button type='primary' onClick={() => history.push("/")}>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default PageNotFound;
