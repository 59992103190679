import { getLocalStorage, msgModal } from "../component/utils/utils";
import { main_api_instance } from "./webapi";
import errorMsg from "../services/pageContent/errorMsg.json";
const webApi = main_api_instance;

export const generateAPIKey = async (userId, onSuccess, onFailure) => {
  try {
    const res = await webApi.get(`/api/apikey/generate/${userId}`);
    if (res.status === 200 && res.data.success) {
      onSuccess(res.data);
    }
  } catch (err) {
    onFailure(err.response?.data?.error || errorMsg.serverError);
  }
};

export const getAPIKeyByUserId = async (userId, onSuccess, onFailure) => {
  try {
    const res = await webApi.get(`/api/apikey/${userId}`);
    if (res.status === 200) {
      onSuccess(res.data);
    }
  } catch (err) {
    onFailure(err.response?.data?.error || errorMsg.serverError);
  }
};
