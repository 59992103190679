import { getLocalStorage, msgModal } from "../component/utils/utils";
import { main_api_instance } from "./webapi";
const webApi = main_api_instance;

export const postQueryMail = async (queryInfo, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/send/query-mail", queryInfo);
    if (res.status === 200 && res.data.success) {
      onSuccess(res.data.msg);
    } else {
      onFailure();
    }
  } catch (err) {
    onFailure(err.response?.data?.error || "Something wents wrong try again later!");
  }
};

export const postContactUsMail = async (queryInfo, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/send/contact-us/mail", queryInfo);
    if (res.status === 200 && res.data.success) {
      onSuccess(res.data);
    }
  } catch (err) {
    onFailure(err.response?.data?.error || "Something wents wrong try again later!");
  }
};
