export const nodeJsSampleColde = `
// Requires "axios" and "form-data" to be installed (see https://www.npmjs.com/package/axios and https://www.npmjs.com/package/form-data)
const axios = require("axios");
const FormData = require("form-data");
const fs = require("fs");
const path = require("path");

// Supported file type is jpg, png, jpeg images
const inputFile = "./file.png";

const formData = new FormData();
formData.append("file", fs.createReadStream(inputFile), path.basename(inputFile));

// file is required to attach "query string"
// if you upload base64 file then you should use encoding_type="base64" other wise encoding_type=[file type]
// 1 encoding_type="base64 or file type"    file type = jpg, png , jpeg
// 2 doc_type="image"

// Here is another query param having boolean value *but these are may increase the processing time*
// 1. upscale=true/false  it is increase the quality of images
// 2. auto_align=true/false  it correct the orientation of images
// 3. remove_noise=true/false  it clean the blurry images

const upscale = false;
const auto_align = false;
const remove_noise = false;

const endpoint = "https://api.alazka.ai/INSERT_YOUR_API_KEY_HERE";

const URL = endpoint + "/?upscale=" + upscale + "&auto_align=" + auto_align + "&remove_noise=" + remove_noise + "&encoding_type=" + path.extname(inputFile) + "&doc_type=image";

axios({
  method: "post",
  url: URL,
  data: formData,
  responseType: "application/json",
  headers: {
    ...formData.getHeaders(),
  },
})
  .then((response) => {
    if (response.status != 200) return console.error("Error:", response.status, response.data);
    console.log(response.data);
    // 🥳 holla extraction success 😎

    // response.data =  {
    //       success:"true",
    //       result:{
    //           text:"....",
    //           linear_text:"....",
    //           connected_text:"....",
    //           box_text:[[...],[...],[....],[...]],  this is used to show highlited text on Image
    //       }
    //       b64:"...."  "high quality image in base64 format"
       //   }
  })
  .catch((error) => {
    return console.error("Request failed 😭:", error);
  });

`;
