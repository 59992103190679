import React, { useEffect } from "react";
import "./UserAccount.scss";
import { Row, Col, Tabs, Space, Table, Tag, Button, message, Input, Form, Switch, Select, List, Badge, Tooltip } from "antd";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";

import { msgModal } from "../utils/utils";
import Highlighter from "react-highlight-words";
import CustomModal from "../CustomModal/CustomModal";

import { useGlobalState } from "../../context/context";
import moment from "moment";
import { getAllUsers, onAdminUpdateUser } from "../../services/adminService";

const { TabPane } = Tabs;
const { Column } = Table;
const { Option } = Select;

const RoleTag = (role) => {
  let view = <></>;
  if (role === "admin") {
    view = (
      <Tag className='user-role-tag' color='#87d068'>
        {role}
      </Tag>
    );
  } else {
    view = <div className='user-role-tag'></div>;
  }

  return view;
};

const AdminControler = ({ selectedTab }) => {
  const [form] = Form.useForm();
  const [globalState, dispatch] = useGlobalState();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isEditModal, setEditModal] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [searchParam, setSearchParam] = React.useState({
    searchText: "",
    searchedColumn: "",
  });
  const [users, setAllUsers] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    getAllUsers(
      (r) => {
        setAllUsers(r);
        setLoading(false);
      },
      (err) => {
        msgModal("error", err, "Error");
        setLoading(false);
      }
    );
  }, [refresh, selectedTab]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchParam({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchParam({ searchText: "" });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size='small' style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    render: (text) =>
      searchParam.searchedColumn === dataIndex ? (
        <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchParam.searchText]} autoEscape textToHighlight={text ? text.toString() : ""} />
      ) : (
        text
      ),
  });

  const editUserByAdmin = (u) => {
    setSelectedUser(u);
    form.setFieldsValue({ leftCredit: u.leftCredit, totalCredit: u.totalCredit, role: u.role, status: u.isActive });
    setEditModal(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setEditModal(false);
  };

  const onUpdateUserInfo = (userInfo) => {
    onAdminUpdateUser(
      { ...userInfo, user_id: selectedUser._id, isActive: selectedUser?.isActive },
      (r) => {
        setSelectedUser(null);
        message.success(r.msg);
        setRefresh(!refresh);
        setEditModal(false);
      },
      (err) => {
        message.error(err);
        setEditModal(false);
        // msgModal("error", "User Can't update", "Error");
      }
    );
  };

  return (
    <div className='admin-controler-wrapper'>
      <Row justify='space-between' align='middle' gutter={[0, 0]}>
        <Col span={12}>
          <h3>Total Users : {users.length}</h3>
        </Col>
        <Col span={12}></Col>
      </Row>

      <div className='mobile-history-list'>
        <List
          itemLayout='horizontal'
          dataSource={users}
          loading={loading}
          pagination={{ pageSize: 8, total: users.length, size: "small", showSizeChanger: true, pageSizeOptions: [10, 20, 60, 100] }}
          renderItem={(user) => (
            <List.Item
              extra={
                <Space size={-5} type='primary' align='end' size='small'>
                  {user.role === "admin" ? (
                    <Tag className='user-role-tag' color='#87d068'>
                      {user.role}
                    </Tag>
                  ) : (
                    <Tag className='user-role-tag'>{user.role}</Tag>
                  )}
                  <Button size='small' className='outline-action-btn' onClick={() => editUserByAdmin(user)}>
                    <EditOutlined style={{ color: "#376dc6" }} />
                  </Button>
                </Space>
              }>
              <List.Item.Meta
                title={
                  <Space>
                    <div>{user.username}</div>
                    <div>
                      {user.isActive ? (
                        <Tooltip title='Active'>
                          <Badge status='success' />
                        </Tooltip>
                      ) : (
                        <Tooltip title='Inactive'>
                          <Badge status='error' />
                        </Tooltip>
                      )}
                    </div>
                  </Space>
                }
                description={
                  <Space direction='vertical' size={0}>
                    <div>{user.email}</div>
                    <div>
                      {moment(user.createdAt).format("MMM D, YYYY ")} |{" "}
                      <span style={{ color: "#376dc6" }}>
                        {user.leftCredit}/{user.totalCredit} Credits
                      </span>
                    </div>
                  </Space>
                }
              />
            </List.Item>
          )}
        />
      </div>
      <div className='desktop-history-list'>
        <Table
          loading={loading}
          dataSource={users}
          scroll={{ y: 550, x: 900 }}
          rowKey={(f) => f._id}
          pagination={{ pageSize: 8, total: users.length, size: "small", showSizeChanger: true, pageSizeOptions: [10, 20, 60, 100] }}
          loading={loading}
          className='conversion-history-table'>
          <Column
            width='20%'
            {...getColumnSearchProps("username")}
            sorter={(a, b) => a.username.length - b.username.length}
            title='Username'
            dataIndex='username'
            key='id'
            className='table-cell-light'
          />
          <Column width='20%' {...getColumnSearchProps("email")} sorter={(a, b) => a.email.length - b.email.length} title='Email' dataIndex='email' key='id' className='table-cell-light' />
          <Column
            width='14%'
            title='Registered On'
            dataIndex=''
            align='right'
            sorter={(a, b) => a.createdAt - b.createdAt}
            render={(f) => <div>{moment(f.createdAt).format("MMM D, YYYY")}</div>}
            key='id'
            className='table-cell-light'
          />
          <Column
            width='8%'
            title='Role'
            dataIndex=''
            filters={[
              { text: "Admin", value: "admin" },
              { text: "User", value: "user" },
            ]}
            onFilter={(value, f) => f.role.includes(value)}
            align='left'
            render={(f) => {
              if (f.role === "admin") {
                return (
                  <div className='user-role-tag'>
                    <Tag color='#87d068'>{f.role}</Tag>
                  </div>
                );
              } else {
                return (
                  <div className='user-role-tag'>
                    <Tag>{f.role}</Tag>
                  </div>
                );
              }
            }}
            key='id'
            className='table-cell-light'
          />
          <Column
            width='10%'
            title='Credit'
            dataIndex=''
            align='right'
            sorter={(a, b) => a.leftCredit - b.leftCredit}
            render={(f) => (
              <div>
                {f.leftCredit}/{f.totalCredit}
              </div>
            )}
            key='id'
            className='table-cell-light'
          />
          {/* <Column title='Left Credit' dataIndex='' align='right' sorter={(a, b) => a.leftCredit - b.leftCredit} render={(f) => <div>{f.leftCredit}</div>} key='id' className='table-cell-light' /> */}
          <Column
            width='8%'
            title='Status'
            dataIndex=''
            align='center'
            render={(f) => <div>{f.isActive ? <Tag className='status-tag active-status'>Active</Tag> : <Tag className='status-tag deactive-status'>Deactive</Tag>}</div>}
            key='id'
            className='table-cell-light'
          />

          <Column
            width='8%'
            title='Action'
            dataIndex=''
            align='right'
            render={(user) => {
              return (
                <Button size='small' className='outline-action-btn' onClick={() => editUserByAdmin(user)}>
                  <EditOutlined style={{ color: "#376dc6" }} />
                </Button>
              );
            }}
            key='id'
            className='table-cell-light'
          />
        </Table>
      </div>

      <CustomModal isModalVisible={isEditModal} handleCancel={closeModal} width={450} height={520} borderRadius>
        <div className='account-edit-modal'>
          <h2 className='title' style={{ marginBottom: 0, color: "#376dc6" }}>
            {selectedUser?.username}
          </h2>
          <h2 className='title'>{selectedUser?.email}</h2>
          <Form form={form} name='user-edit-form' initialValues={{}} layout='vertical' requiredMark={false} className='account-edit-wrapper' onFinish={onUpdateUserInfo}>
            <Form.Item label='Total Credit' name='totalCredit'>
              <Input type='number' placeholder='Total Credit' />
            </Form.Item>
            <Form.Item
              label='Left Credit'
              name='leftCredit'
              dependencies={["totalCredit"]}
              rules={[
                { required: false },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("totalCredit") >= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Left Credit not more than Total Credit"));
                  },
                }),
              ]}>
              <Input type='number' placeholder='Left Credit' />
            </Form.Item>
            <Form.Item label='Role' name='role'>
              <Select className='select-role'>
                <Option value='user'>User</Option>
                <Option value='admin'>Admin</Option>
              </Select>
            </Form.Item>
            <Form.Item name='isActive'>
              <Row justify='space-between' align='center'>
                <Col>
                  <h3 style={{ marginBottom: 0 }}>Active Status</h3>
                </Col>
                <Col>{selectedUser && <Switch name='isActive' checked={selectedUser.isActive} onChange={(checked) => setSelectedUser({ ...selectedUser, isActive: checked })} />}</Col>
              </Row>
            </Form.Item>
            <Form.Item noStyle>
              <Button loading={loading} disabled={false} type='primary' htmlType='submit' className='custom-btn update-btn'>
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </CustomModal>
    </div>
  );
};

export default AdminControler;
