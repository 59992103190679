import { getLocalStorage, msgModal } from "../component/utils/utils";
import { main_api_instance } from "./webapi";
const webApi = main_api_instance;

export const AuthLogin = async (userInfo, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/user/signin", userInfo);
    if (res.status === 200 && res.data.loginSuccess) {
      return onSuccess(res.data);
    } else {
      onFailure(res.data.msg);
    }
  } catch (e) {
    return onFailure("Something wents wrong try again later!");
  }
};

export const userRegister = async (userInfo, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/user/register", { ...userInfo, plan: "free" });
    if (res.status === 200 && res.data.loginSuccess) {
      return onSuccess(res.data);
    } else {
      return onFailure(res.data.msg);
    }
  } catch (e) {
    return onFailure("Something wents wrong! Please try again later");
  }
};

export const forgortPassword = async (email, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/user/forgot-password", email);
    if (res.status === 200) {
      return onSuccess();
    } else {
      onFailure();
    }
  } catch (e) {
    return onFailure("Something wents wrong! Please try again later");
  }
};

export const getUserById = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.get(`/user/userid/${id}`, {
      headers: {
        Authorization: `${getLocalStorage("jwt")}`,
      },
    });
    if (res.status === 200) {
      return onSuccess(res.data);
    } else {
      onFailure(res.data.msg);
    }
  } catch (e) {
    onFailure(e?.response?.data?.msg || "Something wents wrong! User not found");
  }
};

export const getCreditById = async (id, onSuccess, onFailure) => {
  try {
    const res = await webApi.get(`/user/credit/${id}`, {
      headers: {
        Authorization: `${getLocalStorage("jwt")}`,
      },
    });
    if (res.status === 200) {
      return onSuccess(res.data);
    } else {
      onFailure(res.data.msg);
    }
  } catch (e) {
    onFailure(e.response?.data?.error || "Something wents wrong! try again later");
  }
};

export const postUpdateUser = async (userAvatar, name, email, phone, userId, onSuccess, onFailure) => {
  try {
    let formData = new FormData();
    formData.set("avatar", userAvatar);
    formData.set("username", name);
    formData.set("email", email);
    formData.set("phone", phone);
    const res = await webApi.post(`/user/update/${userId}`, formData);
    if (res.status === 200) {
      console.log(res.data);
      return onSuccess();
    }
  } catch (err) {
    onFailure(err?.response?.data.error);
  }
};

export const getUserAvatarById = async (userId, onSuccess, onFailure) => {
  try {
    const res = await webApi.get(`/user/avatar/${userId}`, {
      headers: {
        Authorization: `${getLocalStorage("jwt")}`,
      },
    });
    if (res.status === 200) {
      return onSuccess(res.data);
    }
  } catch (e) {
    onFailure(e.response?.data?.error || "Something wents wrong! try again later");
  }
};

export const getForgortPasswordLink = async (userInfo, onSuccess, onFailure) => {
  try {
    const res = await webApi.post("/api/password-reset/link", userInfo);
    if (res.status === 200 && res.data.isSent) {
      return onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (e) {
    onFailure("Something wrong try again later!");
  }
};

export const resetPassword = async (psInfo, token, onSuccess, onFailure) => {
  try {
    const res = await webApi.post(`/api/password-reset/update/${token}`, psInfo);
    if (res.status === 200 && res.data.success) {
      return onSuccess(res.data);
    } else {
      onFailure(res.data);
    }
  } catch (e) {
    onFailure("Something wrong try again later!");
  }
};

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(data));
    next();
  }
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("jwt")) {
    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    return false;
  }
};

export const Logout = async (onSuccess, onFailure) => {
  if (typeof window !== "undefined") {
    try {
      localStorage.removeItem("jwt");
      onSuccess("You are Logout");
      const res = await webApi.get("/user/signout");
    } catch (err) {
      onFailure("Something Wrong");
    }
  }
};
