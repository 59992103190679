import saveAs from "file-saver";
import { Modal, notification } from "antd";
/*global browser*/

export const getExtension = (filename) => {
  var parts = filename.split("/");
  return parts[parts.length - 1];
};
export const getExtensionFormName = (filename) => {
  var parts = filename.split(".");
  return parts[parts.length - 1];
};

export const getTimeInSec = (ms) => {
  return Math.floor((ms / 1000) % 60).toFixed(2);
};

export const parseMilliseconds = (milliseconds) => {
  const roundTowardsZero = milliseconds > 0 ? Math.floor : Math.ceil;
  return {
    days: roundTowardsZero(milliseconds / 86400000),
    hours: roundTowardsZero(milliseconds / 3600000) % 24,
    minutes: roundTowardsZero(milliseconds / 60000) % 60,
    seconds: roundTowardsZero(milliseconds / 1000) % 60,
    milliseconds: roundTowardsZero(milliseconds) % 1000,
  };
};
export const parseMsInUnit = (milliseconds, unit) => {
  switch (unit) {
    case "day":
      return Number((milliseconds / 86400000).toFixed(1));
    case "hrs":
      return Number((milliseconds / 3600000).toFixed(1));
    case "mins":
      return Number((milliseconds / 60000).toFixed(1));
    case "sec":
      return Number((milliseconds / 1000).toFixed(1));
  }
};
export const getReadableTime = (milliseconds) => {
  if (milliseconds / 86400000 > 1) {
    return `${parseMsInUnit(milliseconds, "day")} day`;
  } else if (milliseconds / 3600000 > 1) {
    return `${parseMsInUnit(milliseconds, "hrs")} hrs`;
  } else if (milliseconds / 60000 > 1) {
    return `${parseMsInUnit(milliseconds, "mins")} min`;
  } else if (milliseconds / 1000 > 1) {
    return `${parseMsInUnit(milliseconds, "sec")} sec`;
  } else {
    return `${milliseconds} ms`;
  }
};
export const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 B";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const downloadTextFile = (fileName, fileType, text, imageText) => {
  if (fileType === "pdf") {
    const zip = require("jszip")();
    var pdfTFolder = zip.folder("pdf-text");
    let imgTFolder = zip.folder("images-text");
    if (imageText.length) {
      let files = imageText;
      for (let i = 0; i < files.length; i++) {
        const imgFileText = new Blob([files[i]], { type: "text/plain;charset=utf-8" });
        const name = fileName.split(".").slice(0, -1).join(".") + ".txt";
        imgTFolder.file(name, imgFileText);
      }
    }
    if (Array.isArray(text)) {
      let files = text;
      for (let i = 0; i < files.length; i++) {
        if (files[i]) {
          const file = new Blob([files[i]], { type: "text/plain;charset=utf-8" });
          let name = fileName.split(".").slice(0, -1).join(".") + "_" + (i + 1) + ".txt";
          pdfTFolder.file(name, file);
        }
      }
    }
    zip.generateAsync({ type: "blob" }).then((content) => {
      const name = fileName.split(".").slice(0, -1).join(".") + ".zip";
      saveAs(content, name);
    });
  } else {
    const file = new Blob([text], { type: "text/plain;charset=utf-8" });
    const name = fileName.split(".").slice(0, -1).join(".") + ".txt";
    saveAs(file, name);
  }
};

export const copyTextFunction = (text, message) => {
  var textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.style.wordSpace = "pre-wrap";
  textField.select();
  document.execCommand("copy");
  message.success("Text Copied!.");
  textField.remove();
};

export const setLocalStorage = (name, data) => localStorage.setItem(name, JSON.stringify(data));
export const getLocalStorage = (name) => JSON.parse(localStorage.getItem(name));

export const msgModal = (type, msg, title) => {
  Modal[type]({
    title: title ?? "Oops! Something wents wrong!",
    className: "msg-modal",
    centered: true,
    getContainer: () => document.getElementById("main-layout"),
    content: (
      <div>
        <p>{msg}</p>
      </div>
    ),
    okButtonProps: { style: {} },
    onOk() {},
  });
};

export const calculatePerValue = (percent, totalValue) => {
  if (percent === 0) {
    return totalValue;
  }
  return Number((Number(totalValue) * (Number(percent) / 100)).toFixed(2));
};

export const supportedFile = ["jpeg", "png", "pdf", "jpg"];

export const truncateString = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

export const openNotification = (type, msg) => {
  notification[type]({
    message: msg,
  });
};

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const avatarColor = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

export const getImageDimensions = (file) => {
  return new Promise(function (resolved, rejected) {
    var i = new Image();
    i.onload = function () {
      resolved({ w: i.width, h: i.height });
    };
    i.src = file;
  });
};

export const fileSizeLimit = (accountType = "anonymousUser") => {
  switch (accountType) {
    case "free":
      return 5242880 * 2;
    case "subscription":
      return 5242880 * 5;
    case "payYougo":
      return 5242880 * 10;
    default:
      return 5242880;
  }
};

export const detectExtension = (extensionId, callback) => {
  var img;
  img = new Image();
  img.src = "chrome-extension://" + extensionId + "/favicon128.png";

  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };

  // var myExtension = browser.management.get(extensionId);
  // if (myExtension === undefined) return callback(false);

  // if (myExtension.enabled) {
  //   callback(true);
  // } else {
  //   callback(false);
  // }
};
