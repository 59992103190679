import React from "react";
import { Row, Col, Space, Button, Checkbox, Select, Tooltip, Divider, Input, Popconfirm, Alert } from "antd";
import { DeleteFilled, EyeOutlined, CloseOutlined } from "@ant-design/icons/lib/icons";
import CustomModal from "../CustomModal/CustomModal";

import "./FileProcessingContainer.scss";
import { truncateString } from "../utils/utils";
import { cancelReq } from "../../services/getOCRResult";
import StatusTag from "../StatusTag/StatusTag";
import { useOCRState } from "../../context/context";
import actionTypes from "../../context/actions";
import OverlayContainer from "../OverlayContainer/OverlayContainer";

const { Option } = Select;

const FileViewCard = ({ file, onCheckModeOpt, isProcessing, freeOCR = false }) => {
  const [{ filesToProccess }, ocrDispatch] = useOCRState();

  const [isImgViewModal, setImgViewModal] = React.useState(false);

  const closeModal = () => {
    setImgViewModal(false);
  };
  const openImgViewnModal = () => {
    setImgViewModal(true);
  };

  const onCancelProcess = () => {
    if (file.type === "pdf") {
      Object.keys(cancelReq).map(function (key, index) {
        if (cancelReq[`${file.uid}-${index}`]) {
          cancelReq[`${file.uid}-${index}`]("process cancelled");
        }
      });
    } else {
      cancelReq[file.uid]("process cancelled");
    }
  };

  // on delete upload process file
  const onDeleteUploadFile = (uid) => {
    ocrDispatch({ type: actionTypes.DELETE_UPLOAD_FILE, payload: uid });
  };

  // change file format
  const onChangeFileFormat = async (format, uid) => {
    ocrDispatch({ type: actionTypes.SET_FILE_FORMAT, payload: { uid: uid, format: format } });
  };

  // select pdf page to process
  const onSelectPdfPage = (e, file) => {
    ocrDispatch({ type: actionTypes.SELECT_PAGES, payload: { file: file, value: e.target.value } });
  };

  if (freeOCR) {
    return <OverlayContainer file={file} />;
  } else {
    return (
      <>
        <Row gutter={[0, 6]} className='file-view-card'>
          <Col span={24}>
            <Row gutter={[0, 12]} justify='space-between' align='middle'>
              <Col sm={14} xm={24}>
                <Tooltip title={file.name}>
                  <div className='file-name'>{truncateString(file.name, 45)}</div>
                </Tooltip>
              </Col>
              <Col sm={10} xm={24}>
                <Space type='primary' size='small' className='align-right'>
                  <StatusTag status={file.status} resTime={file.resTime} errMsg={file.isErrMsg} />
                  {(file.status === "proccessing" || file.status === "re-proccessing") && (
                    <Tooltip title='Cancel process'>
                      <Button size='small' className='view-btn' style={{ borderColor: "red" }} onClick={onCancelProcess}>
                        <CloseOutlined style={{ background: "none", color: "red" }} />
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip title='View upload file'>
                    <Button size='small' disabled={isProcessing} className='view-btn' onClick={openImgViewnModal}>
                      <EyeOutlined style={{ color: "#56A1F8" }} />
                    </Button>
                  </Tooltip>
                  <Popconfirm
                    title='Are you sure want to delete?'
                    onConfirm={() => onDeleteUploadFile(file.uid)}
                    onCancel={() => {}}
                    okText='Yes'
                    cancelText='No'>
                    <Tooltip title='Delete upload file'>
                      <Button size='small' disabled={isProcessing} className='delete-btn'>
                        <DeleteFilled style={{ color: "#56A1F8" }} />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify='space-between' align='middle'>
              <Col sm={9} xm={16}>
                <Space direction='vertical' className='mode-check-boxs' size={0}>
                  <Checkbox
                    disabled={isProcessing}
                    className='checkbox-label'
                    name='correctOrient'
                    checked={file.correctOrient}
                    onChange={(e) => onCheckModeOpt(e, file.uid)}>
                    Correct Orientation
                  </Checkbox>
                  <Checkbox
                    disabled={isProcessing}
                    className='checkbox-label'
                    name='removeNoise'
                    checked={file.removeNoise}
                    onChange={(e) => onCheckModeOpt(e, file.uid)}>
                    Remove Noise
                  </Checkbox>
                </Space>
              </Col>
              <Col sm={6} xm={8} className={file.type === "pdf" ? "pdf-page-select-box" : "img-page-select-box"}>
                {file.type === "pdf" && (
                  <>
                    <span className='pages-label'>Page :</span>
                    <Tooltip title={`Total pages 1-${file.pages}`}>
                      <span className='pages-no'>
                        <Input
                          className='pdf-page-input'
                          min={1}
                          max={file.pages}
                          value={file.selectedPdfPage}
                          onChange={(e) => onSelectPdfPage(e, file)}
                          placeholder={`1-${file.pages}`}
                          style={{ width: "80px" }}
                        />
                      </span>
                    </Tooltip>
                  </>
                )}
              </Col>

              <Col sm={8} xm={8} className='convert-format'>
                <div className='format-label'>Convert Format</div>
                <Select
                  defaultValue='txt'
                  disabled={isProcessing}
                  value={file.fileFormat}
                  className='select-format'
                  style={{ width: 80 }}
                  onChange={(fileFormat) => onChangeFileFormat(fileFormat, file.uid)}>
                  <Option value='txt'>.txt</Option>
                </Select>
              </Col>
            </Row>
          </Col>
          {/* {file.downPer > 0 && (
            <Col span={24}>
              <Progress percent={file.downPer} style={{ height: "3px" }} showInfo={false} size='small' />
            </Col>
          )} */}
        </Row>
        <CustomModal isModalVisible={isImgViewModal} handleCancel={closeModal} height={580} borderRadius>
          <div className='div-center'>
            <OverlayContainer file={file} />
          </div>
        </CustomModal>
      </>
    );
  }
};

export default FileViewCard;
