import axios from "axios";
import { getLocalStorage } from "../../component/utils/utils";

export let node_baseUrl;
export let OCRbaseUrl;
export let gClient_Id;
export let mClient_Id;
export let redirectUri;
export const extensionId = "omdplglehbdkjpihledlkemnlkalgndm";

// console.log(process.env.NODE_ENV);

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  node_baseUrl = process.env.REACT_APP_API_NODE_ENDPOINT_PROD;
  OCRbaseUrl = process.env.REACT_APP_API_OCR_ENDPOINT;
  gClient_Id = process.env.REACT_APP_GG_APP_ID_DEV;
  mClient_Id = process.env.REACT_APP_MICROSOFT_APP_ID_DEV;
  redirectUri = process.env.REACT_APP_API_REDIRECT_ENDPOINT_DEV;
} else {
  node_baseUrl = process.env.REACT_APP_API_NODE_ENDPOINT_PROD;
  OCRbaseUrl = process.env.REACT_APP_API_OCR_ENDPOINT;
  gClient_Id = process.env.REACT_APP_GG_APP_ID;
  mClient_Id = process.env.REACT_APP_MICROSOFT_APP_ID;
  redirectUri = process.env.REACT_APP_API_REDIRECT_ENDPOINT_PROD;
}

// for file processing
let ocr_process_instance = axios.create({
  baseURL: OCRbaseUrl,
  responseType: "json",
});

// for user login and save files
export let main_api_instance = axios.create({
  baseURL: node_baseUrl,
  responseType: "json",
});

main_api_instance.defaults.headers.common["Authorization"] = `${getLocalStorage("jwt")}`;

export default ocr_process_instance;
