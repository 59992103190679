import React, { createContext, useContext, useReducer } from "react";

export const GlobalContext = createContext();
export const OCRContext = createContext();

export const GlobalProvider = ({ children, initialState, reducer }) => (
  <GlobalContext.Provider value={useReducer(reducer, initialState)}>{children}</GlobalContext.Provider>
);

export const OCRProvider = ({ children, initialState, reducer }) => (
  <OCRContext.Provider value={useReducer(reducer, initialState)}>{children}</OCRContext.Provider>
);

export const useGlobalState = () => useContext(GlobalContext);
export const useOCRState = () => useContext(OCRContext);
