import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import "./Layout.scss";
import FooterConsole from "../FooterConsole/FooterConsole";
import { Button, Divider, BackTop } from "antd";
import { VerticalAlignTopOutlined } from "@ant-design/icons";

const Layout = ({ children, faqs }) => {
  return (
    <div className='main-layout' id='main-layout'>
      <Navbar />
      <div className='content-wrapper'>{children}</div>
      <FooterConsole faqs={faqs} />
      <BackTop className='backToTop' duration={100}>
        <Button type='primary' shape='circle' icon={<VerticalAlignTopOutlined />} size='large' />
      </BackTop>
    </div>
  );
};

export default Layout;
