import React from "react";
import Layout from "../../component/Layout/Layout";
import { Steps, Divider, Row, Col } from "antd";
import "./TermsOfService.scss";
import { useGlobalState } from "../../context/context";
import footerContent from "../../services/pageContent/footer.json";

const TermsOfService = () => {
  const [{ lang }, dispatch] = useGlobalState();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout faqs={undefined}>
      <div className='terms-service-wrapper'>
        <div className='header'>
          <h1 className='title'>Terms of Service</h1>
        </div>
        <div className='terms-service-container'>
          <h2>TERMS OF USE</h2>
          <p>
            This website is owned and operated by Alazka.ai Technologies and Systems Private Limited with its registered office at No. 7-3-19, Ferozguda Post, Bowenpally, Hyderabad-500011, Telangana{" "}
            <strong>(“Company”)</strong>. Any reference to in the form of “We”, “Us” and “Our” would hereinafter refer to the Company. Any user of the website shall be termed as the “Customer”, any
            reference to “You”, “Your”, “Yourself” shall hereinafter refer to the Customer.
          </p>
          <p>The given terms and conditions (“Terms of Use”) establish a legally binding agreement between You and the Company,</p>
          <p>
            You agree that your usage of the website shall be subject to these Terms of Use and the Company’s privacy policy which is published on this website and is incorporated by reference in
            these terms.{" "}
          </p>
          <p>
            You further agree, that in using this website, You shall be in compliance with all applicable laws. Please note that the usage of the site is on an `as-is’ and `as-available’ basis. The
            Company does not provide any warranty as to the authenticity of any information which You may obtain as a consequence of using this website.{" "}
          </p>
          <p>
            Please note that these Terms of Use constitute an electronic record according to the provisions of Information Technology Act, 2000 and are generated by a computer system. Hence, there is
            no requirement of affixing a digital or a physical signature therein. This agreement is governed under Indian laws.{" "}
          </p>
          <p>
            You agree that these Terms of Use may be updated from time to time and any such changes will be effective as soon as they are posted. It is advisable to review these Terms of Use from time
            to time so that you remain informed about any such changes.{" "}
          </p>
          <p>PLEASE NOTE THAT IF YOU DO NOT AGREE TO THE GIVEN TERMS OF USE, YOU ARE NOT AUTHORIZED TO ACCESS THE WEBSITE.</p>

          <h2>SCOPE AND APPLICABILITY </h2>
          <p>
            These Terms of Use along with the Privacy Policy shall constitute the entire and sole agreement between You and the Company. The Terms of Use outline the manner in which You are required
            to access and use this website. Any queries regarding Your usage of the website should be directed at: <a href={`mailto:${footerContent[lang].email}`}>{footerContent[lang].email}</a>
          </p>
          <p>You can use the website if:</p>
          <p>2.3. By giving consent, the User agrees that the Operator may store the User’s IP address to verify extensive use of the Platform.</p>

          <h2>3. Rights and obligations of the User</h2>
          <p>
            3.1. The User undertakes to refrain from any actions that endanger the functionality or operation of the software. In particular, the User is prohibited from carrying out any actions that
            scan or test weak points of the software, bypass security systems or access systems of the software or integrate malware into the software.
          </p>
          <p>
            3.2. The User guarantees that he has all the necessary rights (such as copyright, ancillary copyright, industrial property rights, trademark rights) for processing the uploaded photos.
          </p>

          <h2>4. Warranty and liability</h2>
          <ol type='A'>
            <li>You are 18 years of age and above</li>
            <li>You accept to comply with these Terms of Use and the Company’s Privacy Policy. </li>
            <li>You agree that You shall be in compliance with the applicable laws in accessing and using this website. </li>
          </ol>
          <p>
            The website offers services which provide Enterprise Resource Planning (ERP) and Custom IT solutions using AI and ML technologies (<strong>“Services”</strong>)
          </p>
          <p>
            THE SERVICES MAY CHANGE FROM TIME TO TIME AT THE EXCLUSIVE AND SOLE DISCRETION OF THE COMPANY. ANY SUCH CHANGE SHALL BE UPDATED AND REFLECTED IN THESE TERMS OF USE. YOU AGREE TO REVIEW
            THESE TERMS OF USE PERIODICALLY FOR UDATES AND NOT HOLD THE COMPANY RESPONISBLE FOR ANY SUCH CHANGE
          </p>

          <h2>LICENSE</h2>

          <p>
            The Company offers you the usage of this website in strict compliance of these Terms of Use. By agreeing to these Terms of Use, You shall be granted a worldwide, non-exclusive,
            non-transferable, revocable, limited license to access and make personal use of the information and services on the website. The Company at its sole discretion may terminate this license
            at any point of time and for any reason whatsoever. You agree that if you breach these Terms of Use that would constitute sufficient ground for the automatic termination of your license to
            use the website, without any prior notice.
          </p>
          <p>
            You agree that in the event of such termination, you shall destroy all copies of materials which you may have downloaded from the website. Failure to do so shall be construed as infringing
            the Company’s copyright and the Company shall then at its sole discretion may pursue any legal remedies. You agree, that you only possess a limited license to use the website. You do not
            hold any intellectual property right over the website.{" "}
          </p>

          <h2>PROHIBITED USES </h2>

          <p>By using and accessing this website you agree that:</p>
          <ol>
            <li>You are 18 years of age or above. </li>
            <li>
              Your usage of any content available on the website is not, either willfully or otherwise, in non-compliance with any applicable law, rules or regulations. (national, international or
              local){" "}
            </li>
            <li>
              Your usage of this website shall not be in such a manner so as to adversely affect, impair, disable, infect or overburden any of the Company’s servers, computer systems, networks or any
              other components which are deployed to provide You a seamless service.
            </li>
            <li>Your usage of the site shall not interfere with any third-party’s use and enjoyment of the site. </li>
            <li>You shall not modify or alter the content posted or available on the site. </li>
            <li>
              You shall not copy, reverse engineer, compile or commercially or otherwise re-use (including but not limited to selling, renting, leasing, distributing) the content which you may have
              downloaded as a user of this site.{" "}
            </li>
            <li>You shall not without express written consent of the Company make any use of any trademarks, logos or graphics displayed or used on the website for any purpose whatsoever.</li>
            <li>You shall not use the network of this site or the e-mails you may have obtained from this site for the purposes of any advertising, promotions or marketing campaigns. </li>
            <li>
              You shall not upload any content which may be violative of any third-party’s intellectual property or is obscene, pornographic, blasphemous, libelous, pedophilic or otherwise illegal or
              prohibited in any manner whatsoever.
            </li>
          </ol>
          <p>If you are found violating any of the aforementioned restrictions, You agree that, it will be a just and fair action on the Company’s part to initiate legal proceedings against you.</p>
          <h2>DATA PROTECTION </h2>
          <p>
            You agree that You have read and understood in full, the terms of the privacy policy published and displayed on the website. You agree that you are in know of the following and consent to
            the same:
          </p>
          <ol>
            <li>The data being collected by the Company. </li>
            <li>The purpose for which the data is being collected. </li>
            <li>The entities which may process the data. </li>
          </ol>
          <h2>INTELLECTUAL PROPERTY</h2>
          <p>
            You agree that any Services offered, software used, content posted or made available or any other such functionalities which may be connected or associated with the website are the
            intellectual property of the Company. You agree to keep all information pertaining to these confidential at all times. You further agree that you shall not modify, re-sell, reuse, license
            out, distribute or use the intellectual property of the Company in any unauthorized manner. Any use of the intellectual property of the Company shall be made only upon an express written
            authorization and consent of the Company. Any violation of this term shall be considered to be a material breach and the Company is free to pursue any legal remedy it deems fit for the
            enforcement and protection of its rights.
          </p>
          <h2>TRADEMARKS</h2>
          <p>
            You agree that all the trademarks, logos and service marks displayed on the website are property of the Company. Any unauthorized use of the same shall be treated as infringement or
            passing off. Nothing on the website shall be interpreted as granting any form of authorization or license, express/implied, written/unwritten, to any individual to use any trademarks
            belonging to the Company.
          </p>
          <h2>COPYRIGHT NOTICE</h2>
          <p>
            All content published or displayed on the website is the copyright of the Company. You agree that any form of copying, distribution or using for any other purpose of such content without
            an express written permission of the Company shall be treated as copyright infringement.
          </p>
          <h2>INDEMNIFICATION</h2>
          <p>
            You agree to indemnify, defend and hold harmless the Company and any of its affiliates, employees, contractors, professionals and consultants (“The Indemnified Party) from and against any
            claims, damages, cost, liabilities and expenses (including, but not limited to, legal expenses) arising out of Your a) Breach of any applicable law b) Illegal or prohibited use of the
            website d) breach of any intellectual property or confidentiality obligations.
          </p>
          <h2>LIMITATION OF LIABILITY</h2>
          <p>
            You further agree, that the Company shall not be liable for any direct, indirect, consequential or remote damages in connection with these Terms of Use. The Company shall also not be
            liable for any damages that may arise as a result of your misuse of the website or your breach of the obligations of these Terms of Use.
          </p>
          <h2>SEVERABILITY</h2>
          <p>
            If any provision of these Terms of Use becomes invalid or unenforceable due whatsoever reason, such invalidity or unenforceability shall not lead to the entire agreement becoming
            unenforceable. The unenforceable or invalid portion shall be deemed to be severed from these Terms of Use without affecting the validity of the entire Terms of Use.
          </p>
          <h2>GOVERNING LAW AND JURISIDCTION</h2>
          <p>
            All matters, claims and any other such issues which may directly or indirectly arise from or in connection with these Terms of Use shall be governed under the laws of India. The courts at
            Hyderabad shall have the sole and exclusive jurisdiction to hear all matters, claims or any such issues which may directly or indirectly arise from or in connection with the Terms of Use.
          </p>
          <h2>QUESTIONS AND GRIEVANCES</h2>
          <p>
            If you have any queries regarding these Terms of Use, you may contact us at:
            <br />
            <a href={`mailto:${footerContent[lang].email}`}>{footerContent[lang].email}</a>
          </p>
        </div>
      </div>
      <Divider />
    </Layout>
  );
};

export default TermsOfService;
