import react from "react";
import { CheckCircleOutlined, SyncOutlined, CloseCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { getReadableTime } from "../utils/utils";

const StatusTag = ({ status, errMsg = "", resTime = 0 }) => {
  switch (status) {
    case "ready":
      return (
        <Tag color='default' className='status-tag' style={{ color: "#242538" }}>
          ready to process
        </Tag>
      );
    case "proccessing":
      return (
        <Tag icon={<SyncOutlined spin />} className='status-tag' color='processing'>
          processing
        </Tag>
      );
    case "re-proccessing":
      return (
        <Tag icon={<SyncOutlined spin />} className='status-tag' color='processing'>
          re-processing
        </Tag>
      );
    case "success":
      return (
        <Tag icon={<CheckCircleOutlined />} className='status-tag' color='success'>
          Completed in <span>{getReadableTime(resTime)}</span>
        </Tag>
      );
    case "wait":
      return (
        <Tag icon={<MinusCircleOutlined />} className='status-tag' color='default'>
          wait
        </Tag>
      );
    case "error":
      return (
        <Tag icon={<CloseCircleOutlined />} className='status-tag' color='error'>
          {errMsg}
        </Tag>
      );

    default:
      return <Tag color='default'>ready to process</Tag>;
  }
};

export default StatusTag;
