import React from "react";
import { Modal } from "antd";

const CustomModal = ({ isModalVisible, handleCancel, children, width = 1000, height = 500, borderRadius, overflow = "hidden" }) => {
  return (
    <Modal
      destroyOnClose={true}
      centered={true}
      footer={null}
      height={height}
      width={width}
      bodyStyle={{ padding: 0, borderRadius: `${borderRadius ? "8px" : "0"}` }}
      maskClosable={false}
      style={{ overflow: "hidden", borderRadius: `${borderRadius ? "8px" : "0"}` }}
      visible={isModalVisible}
      cancelButtonProps={{ type: "primary" }}
      onCancel={handleCancel}>
      <div style={{ Width: "100%", height: height, padding: "0", overflow: overflow }}>{children}</div>
    </Modal>
  );
};

export default CustomModal;
