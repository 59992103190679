import React from "react";
import { useGlobalState } from "../../context/context";
import { EyeInvisibleOutlined, EyeTwoTone, UndoOutlined } from "@ant-design/icons";
import { Alert, Space, Input, Button, message } from "antd";
import { generateAPIKey, getAPIKeyByUserId } from "../../services/generateApiKey";
import { isAuthenticated } from "../../services/auth";
import jwt_decode from "jwt-decode";

const APIkey = () => {
  const isAuth = isAuthenticated();
  const [APIKey, setAPIKey] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [{ user_info }, dispatch] = useGlobalState();

  const newAPIKey = () => {
    if (isAuth) {
      setLoading(true);
      const decodeUser = jwt_decode(isAuth);
      generateAPIKey(
        decodeUser._id,
        (r) => {
          setLoading(false);
          message.success(r.msg);
          setAPIKey(r.apiKey);
        },
        (err) => {
          setLoading(false);
          message.error(err);
        }
      );
    }
  };

  React.useEffect(() => {
    if (isAuth) {
      const decodeUser = jwt_decode(isAuth);
      getAPIKeyByUserId(
        decodeUser._id,
        (r) => {
          setAPIKey(r.apiKey);
        },
        () => {}
      );
    }
  }, []);

  return (
    <div className='api-key-wrapper'>
      <Alert style={{ textAlign: "center" }} banner showIcon={false} message='To process images and PDF through the api.alazka API, you may need to enter your API key.' type='info' />
      <Space direction='vertical' align='center' className='api-key-box' size='middle'>
        <div className='api-key-label'>API Key:</div>
        {APIKey ? (
          <>
            <Input.Password className='api-key-input' placeholder='API Key' value={APIKey} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            <Button type='primary' icon={<UndoOutlined rotate={90} />} loading={loading} onClick={newAPIKey} className='generate-api-btn'>
              Re-issue
            </Button>
          </>
        ) : (
          <Button type='primary' loading={loading} onClick={newAPIKey} className='generate-api-btn'>
            Get API Key
          </Button>
        )}
      </Space>
    </div>
  );
};

export default APIkey;
