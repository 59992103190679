import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useGlobalState } from "../../context/context";

const UserAvatar = ({ size }) => {
  const [{ user_info, userAvatar }, dispatch] = useGlobalState();
  if (!userAvatar && user_info?.name) {
    return (
      <div className={`user-avatar  ${user_info?.role}-avatar-border`}>
        <Avatar style={{ backgroundColor: "#7265e6", verticalAlign: "middle" }} size={size}>
          {user_info?.name[0].toUpperCase()}
        </Avatar>
      </div>
    );
  } else {
    return (
      <div className={`user-avatar  ${user_info?.role}-avatar-border`}>
        <Avatar size={size} icon={<UserOutlined />} src={userAvatar} />
      </div>
    );
  }
};

export default UserAvatar;
