import React, { useEffect } from "react";
import { Row, Col, Space, Button, Select, message, Tooltip, Alert, Divider } from "antd";
import { EyeOutlined, DownloadOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons/lib/icons";

import CopyIcon from "../../assest/copy-text-icon.svg";
import { bytesToSize, downloadTextFile, copyTextFunction, getImageDimensions, getReadableTime, truncateString } from "../utils/utils";
import CustomModal from "../CustomModal/CustomModal";
import OverlayCanvas from "../OverlayCanvas/OverlayCanvas";
import "./FileProcessingContainer.scss";
import StatusTag from "../StatusTag/StatusTag";
const { Option } = Select;

const FileResultViewCard = ({ file, imgOverlay, setImgOverlay = (f) => {}, freeOCR }) => {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [isImgViewModal, setImgViewModal] = React.useState(false);
  const [imgDimension, setImgDimension] = React.useState(null);

  const [copyText, setCopyText] = React.useState("");
  const [downText, setDownText] = React.useState("");

  const closeModal = () => {
    setImgViewModal(false);
    setImgOverlay(false);
  };
  const openImgViewnModal = () => {
    setImgViewModal(true);
  };

  React.useEffect(() => {
    setImgViewModal(imgOverlay);
  }, [imgOverlay]);

  const incPage = () => {
    if (pageNumber < file.extractionResult?.length + 1) {
      setPageNumber(pageNumber + 1);
    }
  };
  const decPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    if (file.isSuccess) {
      file.extractionResult.map((imgResult, i) => {
        setDownText((prv) => prv + "\n\n" + `Page ${imgResult?.pageNo}\n\n` + imgResult.result.text);
      });
      file.extractionResult.map((imgResult, i) => {
        setCopyText((prv) => prv + "\n\n" + `Page ${imgResult?.pageNo}\n\n` + imgResult.result.text);
      });
    }
  }, []);

  const onClickCopyText = () => {
    copyTextFunction(copyText, message);
  };

  const onClickDownload = () => {
    downloadTextFile(file.name, "png", downText, downText);
  };

  if (freeOCR) {
    return (
      <div className='file-result-view-card free-ocr-result-container' key={file.uid}>
        <Row justify='space-between' align='middle'>
          <Col span={24} className='file-result-header'>
            <Row justify='space-between' align='middle'>
              <Col span={10}>
                <h2 className='result-title'>Processed Result</h2>
              </Col>
              <Col span={14}>
                <Space size='small' style={{ float: "right" }}>
                  <Tooltip title='Copy text'>
                    <Button size='medium' className='copy-txt-btn' style={{ overflow: "hidden" }} onClick={onClickCopyText}>
                      <img src={CopyIcon} alt='Copy Text' /> Copy
                    </Button>
                  </Tooltip>
                  <Tooltip title='Download file'>
                    <Button size='medium' className='down-txt-btn' onClick={onClickDownload}>
                      <DownloadOutlined style={{ color: "#56A1F8" }} /> Download
                    </Button>
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col style={{ width: "100%" }}>
            <div className='free-ocr-result-text'>
              {file.extractionResult?.length > 0 &&
                file.extractionResult.map((extData, i) => {
                  return (
                    <div key={i}>
                      <h2 className='page-no'>Page No : {i + 1}</h2>
                      {extData.result.box_text.map((t, i) => (
                        <p className='text-result' key={i}>
                          {t[1]}
                        </p>
                      ))}
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
        <CustomModal isModalVisible={isImgViewModal} handleCancel={closeModal} overflow='auto' width='auto' height={600} borderRadius>
          <div className='div-center'>
            <Row justify='center' align='middle'>
              <Row className='pdf-view-modal' id='pdf-view' justify='center'>
                {file.extractionResult?.length > 0 && (
                  <Col span={22}>
                    <Row align='middle' justify='center' gutter={[48, 0]}>
                      <Col>
                        <Button type='primary' icon={<LeftOutlined />} onClick={decPage} className='prv-btn' disabled={pageNumber === 1} />
                      </Col>
                      <Col>
                        {pageNumber} / {file.extractionResult?.length}
                      </Col>
                      <Col>
                        <Button
                          type='primary'
                          icon={<RightOutlined />}
                          onClick={incPage}
                          className='next-btn'
                          disabled={pageNumber === file.extractionResult?.length}
                        />
                      </Col>
                    </Row>
                    <Divider style={{ marginBottom: 0 }} />
                  </Col>
                )}
                <Col span={22} className='pdf-view-container'>
                  {file.extractionResult?.length > 0 && (
                    <OverlayCanvas
                      previewImage={`data:image/jpeg;base64,${file.extractionResult[pageNumber - 1].b64}`}
                      dimension={imgDimension}
                      boxes={file.extractionResult[pageNumber - 1].result?.box_text}
                    />
                  )}
                </Col>
              </Row>
            </Row>
          </div>
        </CustomModal>
      </div>
    );
  } else {
    return (
      <div className='file-result-view-card paid-orc-result-container' key={file.uid}>
        <Row justify='space-between' align='middle'>
          <Col span={12}>
            <Space direction='vertical' size={0}>
              <Tooltip title={file.name}>
                <div className='file-name'>{truncateString(file.name, 45)}</div>
              </Tooltip>
              <div className='file-size label-dark'>{bytesToSize(file.size)}</div>
            </Space>
          </Col>
          <Col span='auto'>
            {!file.isErrMsg && file.status !== "re-proccessing" && (
              <Space direction='vertical'>
                <Space size='small'>
                  <Tooltip title='View hilighted text on Image'>
                    <Button size='small' className='view-btn' onClick={openImgViewnModal}>
                      <EyeOutlined style={{ color: "#56A1F8" }} />
                    </Button>
                  </Tooltip>

                  <Tooltip title='Copy text'>
                    <Button size='small' className='view-btn' style={{ overflow: "hidden" }} onClick={onClickCopyText}>
                      <img src={CopyIcon} alt='Copy Text' />
                    </Button>
                  </Tooltip>
                  <Tooltip title='Download file'>
                    <Button size='small' className='delete-btn' onClick={onClickDownload}>
                      <DownloadOutlined style={{ color: "#56A1F8" }} />
                    </Button>
                  </Tooltip>
                </Space>
              </Space>
            )}

            {file.isErrMsg && <div style={{ color: "#f5222d" }}>{file.isErrMsg}</div>}
            {file.status === "re-proccessing" && <StatusTag status='re-proccessing' />}
          </Col>
        </Row>
        <CustomModal isModalVisible={isImgViewModal} handleCancel={closeModal} overflow='auto' width='auto' height={600} borderRadius>
          <div className='div-center'>
            <Row justify='center' align='middle'>
              <Row className='pdf-view-modal' id='pdf-view' justify='center'>
                {file.extractionResult?.length > 0 && (
                  <Col span={22}>
                    <Row align='middle' justify='center' gutter={[48, 0]}>
                      <Col>
                        <Button type='primary' icon={<LeftOutlined />} onClick={decPage} className='prv-btn' disabled={pageNumber === 1} />
                      </Col>
                      <Col>
                        {pageNumber} / {file.extractionResult?.length}
                      </Col>
                      <Col>
                        <Button
                          type='primary'
                          icon={<RightOutlined />}
                          onClick={incPage}
                          className='next-btn'
                          disabled={pageNumber === file.extractionResult?.length}
                        />
                      </Col>
                    </Row>
                    <Divider style={{ marginBottom: 0 }} />
                  </Col>
                )}
                <Col span={22} className='pdf-view-container'>
                  {file.extractionResult?.length > 0 && (
                    <OverlayCanvas
                      previewImage={`data:image/jpeg;base64,${file.extractionResult[pageNumber - 1].b64}`}
                      dimension={imgDimension}
                      boxes={file.extractionResult[pageNumber - 1].result?.box_text}
                    />
                  )}
                </Col>
              </Row>
            </Row>
          </div>
        </CustomModal>
      </div>
    );
  }
};

export default FileResultViewCard;
