import React from "react";
import "./UserAccount.scss";
import { Row, Col, Divider, Tabs, Table } from "antd";
import UserAccountEdit from "./UserAccountEdit";
import ConversionHistTab from "./ConversionHistTab";
import AdminControler from "./AdminControler";
import { EditOutlined } from "@ant-design/icons";
import { useGlobalState } from "../../context/context";
import UserAvatar from "../UserAvatar/UserAvatar";
import { Link } from "react-router-dom";
import APIkey from "./APIkey";
import { useLocation } from "react-router-dom";

const { TabPane } = Tabs;
const { Column } = Table;

const UserAccount = () => {
  const location = useLocation();
  const [isUserEditModal, setUserEditModal] = React.useState(false);
  const [{ user_info }, dispatch] = useGlobalState();
  const [selectedTab, setSeletedTab] = React.useState(location.state.id ?? "3");

  const closeModal = () => {
    setUserEditModal(false);
  };
  const openUserEditModal = () => {
    setUserEditModal(true);
  };

  const onTabChange = (key) => {
    setSeletedTab(key);
  };

  return (
    <div className='dashboard-wrapper'>
      <h1 className='page-title'>My Account</h1>
      <div className='user-account-wrapper'>
        <Row>
          <Col lg={5} md={24}>
            <div className='user-personal-info'>
              <div className='user-info-top'>
                <UserAvatar size={100} />
                <div className='user-name'>{user_info?.name}</div>
                {user_info?.name && (
                  <div className='user-credit-balance'>
                    {user_info?.role === "user" && (
                      <>
                        <span className='credit-logo'>c</span> {user_info?.leftCredit} of {user_info?.totalCredit} Credit Left
                      </>
                    )}
                    {user_info?.role === "admin" && (
                      <>
                        <span className='credit-logo'>c</span> {user_info?.leftCredit} Credit
                      </>
                    )}
                  </div>
                )}
                {/* <Link to='/upgrade-plan' className='upgrade-plan-btn'>  temprory closed
                  Upgrade Plan
                </Link> */}
              </div>
              <div className='user-details'>
                <div className='user-detail-header'>
                  <div className='detail-label'>Details</div>
                  <div className='user-setting' onClick={openUserEditModal}>
                    <EditOutlined />
                    Edit
                  </div>
                </div>
                <Divider style={{ margin: "10px 0" }} />
                <div className='user-info'>
                  <div className='label'>Account Details</div>
                  <div className='user-name label-dark'>{user_info?.name}</div>
                  <div className='user-email label-dark'>{user_info?.email}</div>
                  {user_info?.phone && <div className='user-email label-dark'>Phone : {user_info?.phone}</div>}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={19} md={24}>
            <div className='user-account-info'>
              <Tabs defaultActiveKey='3' activeKey={selectedTab} onChange={onTabChange}>
                <TabPane tab='Conversions History' key='3' className='tab-content'>
                  <ConversionHistTab />
                </TabPane>
                {user_info?.role === "admin" && (
                  <TabPane tab='Admin Dashboard' key='4' className='tab-content'>
                    <AdminControler selectedTab={selectedTab} />
                  </TabPane>
                )}
                <TabPane tab='API Key' key='5' className='tab-content'>
                  <APIkey />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
      {user_info !== null && <UserAccountEdit isUserEditModal={isUserEditModal} closeModal={closeModal} user={user_info} />}
    </div>
  );
};

export default UserAccount;
