import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Space, Carousel } from "antd";

import ArrowCurveUp from "../../assest/arrow-curve-up.svg";
import FileUploadContainer from "../FileUploadContainer/FileUploadContainer";
import langContent from "../../services/pageContent/HeroBanner.json";

import "./HeroBanner.scss";
import BannerCarousel from "../BannerCarousel/BannerCarousel";
import { useGlobalState, useOCRState } from "../../context/context";

const HeroBanner = () => {
  const [globalState, dispatch] = useGlobalState();
  const [{ filesToProccess }, ocrDispatch] = useOCRState();

  const [pageContent, setPageContent] = React.useState(langContent[globalState.lang]);

  React.useEffect(() => {
    setPageContent(langContent[globalState.lang]);
  }, [filesToProccess.length, globalState.lang]);

  return (
    <section className='hero-banner-wrapper'>
      <Row justify='space-around' className='banner-content' align='middle'>
        <Col md={12} sm={24}>
          <h1 className='banner-title' data-aos='fade-down' data-aos-duration='800'>
            <Space direction='vertical' size={0}>
              <div>{pageContent.title[0]}</div>
              <div>{pageContent.title[1]}</div>
            </Space>
          </h1>
          <h4 className='banner-sub-title' data-aos='fade-down' data-aos-duration='800'>
            <Space direction='vertical' size={0}>
              <div>{pageContent.subTitle}</div>
            </Space>
          </h4>
          <div data-aos='fade-down' data-aos-delay='500' data-aos-duration='1000' data-aos-anchor-placement='bottom-bottom'>
            <BannerCarousel />
          </div>
          <img
            src={ArrowCurveUp}
            alt='Arrow up'
            className='arrow-line-down-img'
            data-aos='fade-right'
            data-aos-delay='600'
            data-aos-duration='1000'
            width={300}
          />
        </Col>
        <Col md={9} sm={24}>
          <div data-aos='fade-down' data-aos-duration='500' data-aos-delay='700'>
            <FileUploadContainer multiple={false} freeOCR={true} />
          </div>
        </Col>
      </Row>
      <div className='curve-wave'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
          <path
            fill='#d0eaff'
            fillOpacity='1'
            d='M0,64L60,90.7C120,117,240,171,360,181.3C480,192,600,160,720,133.3C840,107,960,85,1080,85.3C1200,85,1320,107,1380,117.3L1440,128L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z'></path>
        </svg>
      </div>
    </section>
  );
};

export default HeroBanner;
