import React from "react";
import CustomModal from "../CustomModal/CustomModal";
import { Row, Col, Form, Input, Button, message, Avatar, Upload } from "antd";
import { UserOutlined, CameraFilled } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { postUpdateUser } from "../../services/auth";
import { useGlobalState } from "../../context/context";
import actionTypes from "../../context/actions";
import UserAvatar from "../UserAvatar/UserAvatar";
import { msgModal } from "../utils/utils";

const UserAccountEdit = ({ isUserEditModal, closeModal, user }) => {
  const [{ user_info, userAvatar }, dispatch] = useGlobalState();

  const [updateUser, setUpdateUser] = React.useState({
    viewAvatar: null,
    updatePic: null,
  });
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const onUpdateUserInfo = (u) => {
    form
      .validateFields()
      .then((r) => {
        setLoading(true);
        postUpdateUser(
          updateUser.viewAvatar,
          u.name,
          u.email,
          u.phone,
          user.user_id,
          (r) => {
            setLoading(false);
            closeModal();
            message.success(`Profile Updated`);
            dispatch({ type: actionTypes.REFRESH, payload: {} });
          },
          (err) => {
            setLoading(false);
            closeModal();
            msgModal("info", err, "Info");
          }
        );
      })
      .catch((e) => {
        closeModal();
        msgModal("info", "Validate Error on Update User", "Info");
      });
  };

  React.useEffect(() => {
    if (userAvatar !== null) {
      setUpdateUser((prv) => ({ ...prv, viewAvatar: userAvatar }));
    } else {
      setUpdateUser((prv) => ({ ...prv, viewAvatar: null }));
    }
  }, [userAvatar]);

  const props = {
    name: "file",
    multiple: false,
    accept: ".jpeg,.jpg,.png",
    beforeUpload: (file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        setUpdateUser({ ...updateUser, viewAvatar: reader.result, updatePic: file });
      };
      return false;
    },
  };
  return (
    <CustomModal isModalVisible={isUserEditModal} handleCancel={closeModal} width={450} height={520} borderRadius>
      <div className='account-edit-modal'>
        <Row justify='center' align='middle'>
          <Col>
            <div className={`user-avatar  ${user.role}-avatar-border`}>
              {updateUser.viewAvatar ? (
                <Avatar size={130} icon={<UserOutlined />} src={updateUser.viewAvatar} />
              ) : (
                <Avatar size={130} style={{ backgroundColor: "#7265e6", verticalAlign: "middle" }}>
                  {user_info?.name[0].toUpperCase()}{" "}
                </Avatar>
              )}
              <Upload {...props} showUploadList={false}>
                <div className='camera-icon'>
                  <CameraFilled />
                </div>
              </Upload>
            </div>
          </Col>
        </Row>
        <h2 className='account-edit-title'>Update Account Details</h2>
        <Form
          form={form}
          name='account-edit-form'
          initialValues={{ name: user.name, email: user.email, phone: user.phone }}
          layout='vertical'
          requiredMark={false}
          className='account-edit-wrapper'
          onFinish={onUpdateUserInfo}>
          <Form.Item name='name'>
            <Input placeholder='Name' />
          </Form.Item>
          <Form.Item
            name='email'
            rules={[
              {
                type: "email",
                message: "This email is not valid E-mail!",
              },
            ]}>
            <Input placeholder='Email' />
          </Form.Item>
          <Form.Item name='phone'>
            <Input type='number' placeholder='Phone' rules={[{ min: 10, max: 12 }]} />
          </Form.Item>
          <Form.Item noStyle>
            <Button loading={loading} disabled={false} type='primary' htmlType='submit' className='custom-btn update-btn'>
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </CustomModal>
  );
};

export default UserAccountEdit;
