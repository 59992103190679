import { useRef, useEffect } from "react";
import { getImageDimensions } from "../utils/utils";

const OverlayCanvas = ({ previewImage, boxes, dimension = null }) => {
  console.log(dimension);
  const canvasRef = useRef();
  useEffect(async () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    let img = new Image();
    img.onload = function () {
      canvas.setAttribute("width", dimension?.w || img.width + "px");
      canvas.setAttribute("height", dimension?.h || img.height + "px");
      context.drawImage(img, 0, 0, dimension?.w || img.width, dimension?.h || img.height, 0, 0, canvas.width, canvas.height);
      if (boxes && boxes?.length > 0) {
        boxes.forEach((box) => {
          let x = box[0][0];
          let y = box[0][2];
          let w = box[0][1] - box[0][0];
          let h = box[0][3] - box[0][2];
          context.beginPath();
          context.lineWidth = "3";
          context.strokeStyle = "red";
          context.rect(x, y, w, h);
          context.stroke();
        });
      }
    };
    img.src = `${previewImage}`;
    // context.fillStyle = '#000000'

    // context.fillRect(0, 0, context.canvas.width, context.canvas.height)
  }, [boxes, previewImage]);

  return <canvas ref={canvasRef} />;
};
export default OverlayCanvas;
