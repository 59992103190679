import React from "react";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../services/auth";

import HeroBanner from "../../component/HeroBanner/HeroBanner";
import ImgProcessStep from "../../component/ImgProcessStep/ImgProcessStep";
import ClientFeedback from "../../component/ClientFeedBack/ClientFeedback";
import Layout from "../../component/Layout/Layout";
import HomePagefaqs from "../../services/pageContent/homePageFaq.json";
import { useGlobalState } from "../../context/context";
import "./LandingPage.scss";

const LandingPage = () => {
  const history = useHistory();
  const [globalState, dispatch] = useGlobalState();
  const [faqContent, setFaqContent] = React.useState(HomePagefaqs[globalState.lang]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (isAuthenticated()) {
      history.push("/ocr-process");
    }
  }, []);
  React.useEffect(() => {
    setFaqContent(HomePagefaqs[globalState.lang]);
  }, [globalState.lang]);
  return (
    <Layout isLandingPage faqs={faqContent}>
      <div className='landing-page-wrapper'>
        <HeroBanner />
        <ImgProcessStep />
        <ClientFeedback />
      </div>
    </Layout>
  );
};

export default LandingPage;
