import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import PrivateRoute from "./privateRoute";
import actionTypes from "../context/actions";
import { useGlobalState } from "../context/context";
import { detectExtension, getLocalStorage, msgModal } from "../component/utils/utils";

import LandingPage from "../Page/LandingPage/LandingPage";
import FreeOCR from "../Page/FreeOCR/FreeOCR";
import PaidOCR from "../Page/PaidOCR/PaidOCR";
import PasswordRestPage from "../Page/PasswordResetPage/PasswordResetPage";
import jwt_decode from "jwt-decode";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { getUserAvatarById, getUserById } from "../services/auth";
import PageNotFound from "../Page/PageNotFound/PageNotFound";
import Pricing from "../Page/Pricing/Pricing";
import HowToUse from "../Page/HowToUse/HowToUse";
import PrivacyPolicy from "../Page/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "../Page/TermsOfServic/TermsOfService";
import UpgradePlan from "../Page/UpgradePlan/UpgradePlan";
import ToolsAndApi from "../Page/ToolsAndApi/ToolsAndApi";
import UserAccount from "../Page/UserAccount/UserAccount";
import chromeInstallBanner from "../component/ChromeExtBanner/ChromeExtBanner";
import { extensionId } from "../services/webapi";

const MyRoutes = () => {
  const [globalState, dispatch] = useGlobalState();

  useEffect(() => {
    AOS.init({
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "",
    });
    let id;
    // detectExtension(extensionId, (isDownload) => {
    //   console.log(isDownload);
    //   if (!isDownload) {
    //     id = setTimeout(() => {
    //       chromeInstallBanner();
    //     }, 1000);
    //   }
    // });
    // return () => clearTimeout(id);

    if (getLocalStorage("lang")) {
      dispatch({ type: actionTypes.LANG, payload: getLocalStorage("lang") });
    } else {
      dispatch({ type: actionTypes.LANG, payload: "English" });
    }
  }, []);

  useEffect(() => {
    if (getLocalStorage("jwt")) {
      const decodeUser = jwt_decode(getLocalStorage("jwt"));
      getUserById(
        decodeUser._id,
        (r) => {
          dispatch({ type: actionTypes.SET_USER, payload: r });
        },
        (err) => {
          msgModal("info", "Something wents wrong! Please try again later", "Info");
        }
      );

      getUserAvatarById(
        decodeUser._id,
        (res) => {
          if (res !== null) {
            const base64String = btoa(
              new Uint8Array(res.data).reduce(function (data, byte) {
                return data + String.fromCharCode(byte);
              }, "")
            );
            dispatch({ type: actionTypes.USER_AVATAR, payload: `data:image/jpeg;base64,${base64String}` });
          } else {
            dispatch({ type: actionTypes.USER_AVATAR, payload: null });
          }
        },
        (err) => {
          msgModal("error", err, "Error");
        }
      );
    }
  }, [getLocalStorage("jwt"), globalState.refresh]);

  // check your online and offline
  window.addEventListener("online", function () {
    dispatch({ type: actionTypes.IS_OFFLINE, payload: false });
  });
  window.addEventListener("offline", function () {
    dispatch({ type: actionTypes.IS_OFFLINE, payload: true });
  });

  return (
    <Router>
      <Spin spinning={globalState.loading} indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />} style={{ maxHeight: "600px" }}>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/upload' component={FreeOCR} />
          {/* <Route exact path='/pricing' component={Pricing}  temprory closed/>
          <PrivateRoute exact path='/upgrade-plan' component={UpgradePlan} />  temprory closed*/}
          <Route exact path='/how-to-use' component={HowToUse} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route exact path='/terms-of-service' component={TermsOfService} />
          <Route path='/password/reset/:token' exact component={PasswordRestPage} />
          <Route path='/tools-and-api' exact component={ToolsAndApi} />
          <PrivateRoute path='/ocr-process' exact component={PaidOCR} />
          <PrivateRoute path='/account' exact component={UserAccount} />
          <Route path='*' component={PageNotFound} />
        </Switch>
      </Spin>
    </Router>
  );
};

export default MyRoutes;
