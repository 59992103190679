import React, { useEffect } from "react";
import "./UserAccount.scss";
import { Row, Col, Divider, Tabs, Avatar, Space, Input, Table, Tag, Button, message, List, Tooltip } from "antd";
import { EyeOutlined, SearchOutlined, GlobalOutlined, DownloadOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import { getAllProcessFile, deleteProcessFile } from "../../services/uploadProcessService";
import { downloadTextFile, getImageDimensions, getLocalStorage } from "../utils/utils";
import CustomModal from "../CustomModal/CustomModal";
import OverlayCanvas from "../OverlayCanvas/OverlayCanvas";
import Highlighter from "react-highlight-words";
import jwt_decode from "jwt-decode";

import { useGlobalState } from "../../context/context";
import moment from "moment";
import { Logout } from "../../services/auth";

const { TabPane } = Tabs;
const { Column } = Table;

const ConversionHistTab = () => {
  const [imgDimension, setImgDimension] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [viewImgs, setViewImgs] = React.useState([]);

  const [overlayImgModal, setOverlayModal] = React.useState({
    base64Img: null,
    box_text: null,
    isOpen: false,
  });
  const [globalState, dispatch] = useGlobalState();
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedRowkey, setSelectedRowKey] = React.useState([]);
  const [processFiles, setAllProcessFiles] = React.useState([]);

  const [filePerPage, setFilePerPage] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalFiles, setTotalFiles] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const [searchParam, setSearchParam] = React.useState({
    searchText: "",
    searchedColumn: "",
  });

  useEffect(() => {
    setLoading(true);
    if (getLocalStorage("jwt")) {
      const decodeUser = jwt_decode(getLocalStorage("jwt"));
      getAllProcessFile(
        currentPage,
        filePerPage,
        decodeUser._id,
        (r) => {
          setAllProcessFiles(r.files);
          setTotalFiles(r.totalFiles);
          setTotalPages(r.totalPages);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      );
    } else {
      Logout(
        () => {},
        () => {}
      );
    }
  }, [refresh, currentPage, filePerPage]);

  const onDeleteUploadFile = () => {
    selectedRowkey.map((serial_id, i) => {
      deleteProcessFile(
        globalState.user_info?.user_id,
        serial_id,
        (r) => {
          message.success(r);
          setSelectedRowKey([]);
        },
        (err) => {
          message.error(err);
        }
      );
    });
    setRefresh(!refresh);
  };
  const onClickViewImg = async (file) => {
    setViewImgs(file.extractionResult);
    setOverlayModal({ isOpen: true });
    // const dimension = await getImageDimensions(`data:image/jpeg;base64,${base64String}`);
    // setImgDimension(dimension);
  };

  const closeModal = () => {
    setOverlayModal({ base64Img: null, isOpen: false, box_text: null });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchParam({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchParam({ searchText: "" });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    render: (text) =>
      searchParam.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchParam.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const showTotalPerPage = (pageNo, perPage) => {
    setCurrentPage(pageNo);
    setFilePerPage(perPage);
  };

  const makeFileToDown = async (file) => {
    return new Promise(async (resolved, reject) => {
      let text = "";
      let allPromise = file.extractionResult.map(async (imgResult, i) => {
        text = text + "\n\n" + `Page ${imgResult?.pageNo}\n\n` + imgResult.result.text;
      });

      await Promise.all(allPromise).then((res) => {
        resolved(text);
      });
    });
  };

  const onClickFileDownload = async (file) => {
    const allText = await makeFileToDown(file);
    downloadTextFile(file.name, "png", allText, allText);
  };

  const incPage = () => {
    if (pageNumber < viewImgs.length + 1) {
      setPageNumber(pageNumber + 1);
    }
  };
  const decPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div className='conversion-history-wrapper'>
      <Row justify='space-between' align='middle' className='table-header-row' gutter={[0, 0]}>
        <Col span={12}>
          <div className='label-dark'>Total files: {totalFiles}</div>
        </Col>
        <Col span={12}>
          <div className='label-dark'>Total Pages: {totalPages}</div>
        </Col>
      </Row>
      <div className='mobile-history-list'>
        <List
          itemLayout='horizontal'
          dataSource={processFiles}
          loading={loading}
          pagination={{
            pageSize: filePerPage,
            current: currentPage,
            total: totalFiles,
            size: "small",
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 40, 60, 100],
            onChange: showTotalPerPage,
          }}
          renderItem={(file) => (
            <List.Item
              extra={
                <Space type='primary' align='end' size='small'>
                  <Tooltip title='Download file'>
                    <Button size='small' className='outline-action-btn' onClick={() => onClickFileDownload(file)}>
                      <DownloadOutlined style={{ color: "#376dc6" }} />
                    </Button>
                  </Tooltip>
                  <Tooltip title='View Images'>
                    <Button size='small' className='outline-action-btn' onClick={() => onClickViewImg(file)}>
                      <EyeOutlined style={{ color: "#376dc6" }} />
                    </Button>
                  </Tooltip>
                </Space>
              }>
              <List.Item.Meta
                title={<span>{file.name}</span>}
                description={
                  <div>
                    {moment(file.updatedAt).format("MMM D, YYYY h:mm A ")} |{" "}
                    <span className='no-of-files' style={{ color: "#376dc6" }}>
                      Pages {file.noOf_pages}
                    </span>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </div>
      <div className='desktop-history-list'>
        <Table
          loading={loading}
          dataSource={processFiles}
          rowKey={(f) => f.serial_id}
          scroll={{ y: 550, x: 600 }}
          pagination={{
            pageSize: filePerPage,
            current: currentPage,
            total: totalFiles,
            size: "small",
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 40, 60, 100],
            onChange: showTotalPerPage,
          }}
          className='conversion-history-table'>
          <Column
            title='Name'
            width='40%'
            {...getColumnSearchProps("name")}
            filters={[
              { text: "PDF", value: ".pdf" },
              { text: "JPG", value: ".jpg" },
              { text: "PNG", value: ".png" },
              { text: "JPEG", value: ".jpeg" },
            ]}
            onFilter={(value, f) => f.name.includes(value)}
            dataIndex='name'
            key='id'
            className='table-cell-light'
          />
          <Column
            title='Processed On'
            dataIndex=''
            sorter={(a, b) => a.updatedAt - b.updatedAt}
            render={(f) => <div>{moment(f.updatedAt).format("MMM D, YYYY h:mm A ")}</div>}
            key='id'
            className='table-cell-light'
          />
          <Column
            title='Pages'
            dataIndex=''
            width={100}
            sorter={(a, b) => a.noOf_pages - b.noOf_pages}
            align='right'
            render={(f) => <div className='no-of-files'>{f.noOf_pages}</div>}
            key='id'
            className='table-cell-light'
          />
          <Column
            title='Action'
            dataIndex=''
            align='right'
            render={(file) => {
              return (
                <Row justify='end' align='middle' className='table-actions-btns'>
                  <Col>
                    <Space type='primary' align='end' size='small'>
                      <Tooltip title='Download file'>
                        <Button size='small' className='outline-action-btn' onClick={() => onClickFileDownload(file)}>
                          <DownloadOutlined style={{ color: "#376dc6" }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title='View Images'>
                        <Button size='small' className='outline-action-btn' onClick={() => onClickViewImg(file)}>
                          <EyeOutlined style={{ color: "#376dc6" }} />
                        </Button>
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
              );
            }}
            key='id'
            className='table-cell-light'
          />
        </Table>
      </div>

      <CustomModal isModalVisible={overlayImgModal.isOpen} handleCancel={closeModal} overflow='auto' width='auto' height={600} borderRadius>
        <div className='div-center'>
          <Row justify='center' align='middle'>
            <Row className='pdf-view-modal' id='pdf-view' justify='center'>
              {viewImgs?.length > 0 && (
                <Col span={22}>
                  <Row align='middle' justify='center' gutter={[48, 0]}>
                    <Col>
                      <Button type='primary' icon={<LeftOutlined />} onClick={decPage} className='prv-btn' disabled={pageNumber === 1} />
                    </Col>
                    <Col>
                      {pageNumber} / {viewImgs?.length}
                    </Col>
                    <Col>
                      <Button
                        type='primary'
                        icon={<RightOutlined />}
                        onClick={incPage}
                        className='next-btn'
                        disabled={pageNumber === viewImgs?.length}
                      />
                    </Col>
                  </Row>
                  <Divider style={{ marginBottom: 0 }} />
                </Col>
              )}
              <Col span={22} className='pdf-view-container'>
                {viewImgs?.length > 0 && (
                  <OverlayCanvas
                    previewImage={`data:image/jpeg;base64,${viewImgs[pageNumber - 1].b64}`}
                    dimension={imgDimension}
                    boxes={viewImgs[pageNumber - 1].result?.box_text}
                  />
                )}
              </Col>
            </Row>
          </Row>
        </div>
      </CustomModal>
    </div>
  );
};

export default ConversionHistTab;
