import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import Routes from "./route";

// context import
import { GlobalProvider, OCRProvider } from "./context/context";
import reducer, { initialState } from "./context/reducer";
import ocrReducer, { ocrIniState } from "./context/ocrReducer";

ReactDOM.render(
  <GlobalProvider initialState={initialState} reducer={reducer}>
    <OCRProvider initialState={ocrIniState} reducer={ocrReducer}>
      <Routes />
    </OCRProvider>
  </GlobalProvider>,
  document.getElementById("root")
);
