import React from "react";
import Layout from "../../component/Layout/Layout";
import { Divider } from "antd";
import "./PrivacyPolicy.scss";
import packageJson from "../../../package.json";
import { useGlobalState } from "../../context/context";
import footerContent from "../../services/pageContent/footer.json";

const PrivacyPolicy = () => {
  const [{ lang }, dispatch] = useGlobalState();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout faqs={undefined}>
      <div className='privacy-policy-wrapper'>
        <div className='header'>
          <h1 className='title'>Privacy Policy</h1>
          <p className='desc'>Version: {packageJson.version}</p>
        </div>
        <div className='privacy-policy-container'>
          <h2>Effective Date: 1st March 2022 </h2>
          <p>
            Alazka.ai Technologies and Systems Private Limited <strong>(“Company”)</strong> is committed to protecting the privacy and security of your personal information. This Privacy Policy
            explains the privacy practices and policies adopted by the Company and covers the data processing activities of the company as a data controller.
          </p>
          <p>By accessing or using the website, you agree that You have read, understood and accepted the terms of this Privacy Policy. </p>
          <p>This Privacy Policy and the Terms of Use, as published on the website are to be read together and form one single legally binding agreement between You and the Company.</p>
          <h2>1. Definitions</h2>
          <p>
            <strong>“Personal Information”</strong> means any information which relates to a living, identifiable person. It may include information such as name, address, email and any other
            identifier which is used to identify a person. However, it may also include certain sensitive information such as credit card number, health reports, bank account number etc. or any data
            that renders a person identifiable.
          </p>

          <p>“Processing” includes all activities relating to the use of personal data by the Company which is and not limited to data collection, storage, and sharing of the data. </p>

          <p>
            “Data Subject” is the person whose personal information is being collected. “Services” include any service which may be provided to You via the website, which includes but is not limited
            to Enterprise Resource Planning (ERP) and Custom IT solutions using AI and ML technologies.
          </p>

          <h2>2. What Information May Be Collected </h2>

          <h3>2.1 Contact information:</h3>
          <p>The Company may collect any contact information from You such as your name, address, phone number, email ID etc.</p>

          <h3>2.2 Information to provide the Services:</h3>
          <p>The Company may collect information regarding the queries You may have or the solutions You require, in order to deliver You seamless Services. s.</p>

          <h3>2.3 Payment and billing information:</h3>
          <p>The Company may collect your billing name, billing address and payment method when you pay for the Services availed by you on the website.</p>

          <h3>2.4 Other information:</h3>
          <p>While you use the website, the Company may collect information about your IP address and the browser you are using</p>

          <p>
            You acknowledge and agree that any and all information You provide on the website is correct and accurate and is provided by You with Your informed and voluntary consent. The Company shall
            not be liable for the genuineness or the accuracy of the information provided by You on the website. You shall be exclusively liable for the consequences of providing incomplete,
            inaccurate or incorrect information on the website. If it is found by the Company that You provided false information, the Company may at its sole discretion, prevent You from accessing
            the website.
          </p>

          <h2>3. How does the Company collect the data </h2>

          <h3>3.1 Personal Data provided to the Company</h3>
          <p>
            You willingly consent to provide the Company your personal data when you provide Your personal data on the website. You also consent to sharing your personal data with the Company when you
            subscribe to receive our marketing.
          </p>

          <h3>3.2 Personal data collected automatically</h3>
          <p>
            Some data such as your browser type, internet protocol address used, your usage habits may be automatically collected by the Company using cookies. Cookies are tiny bits of information
            which a website sends to Your computer resource. We use Cookies to provide you with a more personalized experience on the website. You can change Your browser settings and choose to
            disable sharing of information through cookies.
          </p>

          <h2>4. What Purposes is the Data Collected Used For? </h2>
          <p>The purposes for which the Company uses the data collected are:</p>
          <ul>
            <li>
              Verifying your identity: The contact details collected may be used to verify your identity. You agree that such verification is essential for the Company to run the website smoothly and
              to comply with its legal and other obligations.{" "}
            </li>
            <li>Offering You Services in a smooth and effective manner. </li>
            <li>
              Personalizing your experience on the website: The information collected from you would help the Company in personalizing your experience and providing you a better and more efficient
              service.{" "}
            </li>
            <li>Improving the design and style of website. </li>
            <li>Providing you with communication regarding offers, products or services, if you have chosen to subscribe to such communication. </li>
            <li>For security purposes: The Company might use the information provided by you on the website to protect our company, our users, our partners or our associates. </li>
          </ul>
          <p>You agree that the aforementioned purposes are valid and give Your informed consent to the collection of data for such purposes.</p>

          <h2>5. What is the legal basis for collection of data? </h2>
          <p>The Company collects data from you on the following legal basis:</p>
          <ol type='a'>
            <li>
              Performance of a contract with you as a consumer: By accepting the Terms of Use which have been separately published on the website, You agree to enter into a binding legal agreement
              with the Company. In pursuance of the same, You agree, that, for a valid performance of that contract, it is necessary, that the Company shall collect Your data for the purposes
              mentioned above.{" "}
            </li>
            <li>To protect your vital interests by connecting you with service providers. </li>
            <li>On the basis of consent obtained from you. </li>
          </ol>

          <h2>6. How long will the data collected be retained by the Company? </h2>
          <p>The Company shall not retain your data longer than is necessary for it to fulfill the aforementioned purposes. </p>
          <p>Any information that is provided by you may be retained by the Company for:</p>

          <ol type='i'>
            <li>Providing the Services; and/or </li>
            <li>For meeting its obligations under applicable law. </li>
            <li>For providing any promotional or advertising materials to You, if You have chosen to subscribe to the same. </li>
          </ol>
          <p>
            Even if you stop accessing or using the website, the Company may have to retain the information provided by you for a short period of time until the same can be erased from its servers,
            and for compliance with its obligations under applicable law (if any).{" "}
          </p>
          <p>
            If you want to withdraw the consent granted to the website for collecting and processing your data, please notify the Company at{" "}
            <a href={`mailto:${footerContent[lang].email}`}>{footerContent[lang].email}</a> Once you discontinue the use of the website or withdraw your consent, the Company shall retain the personal
            information provided by you on the website for such time period as may be mandated by applicable law (if any) and thereafter erase the same in full.{" "}
          </p>

          <h2>7. Processing of the data collected by the Website </h2>
          <p>
            All data that is shared by you on the website may be processed by the Company. Personal data, You provide shall only be processed for the sole purpose of providing the Services as offered
            by this website and to contact you. You can choose to at any point of time to revoke your consent for the processing of the data shared by you on the website, by sending an email to{" "}
            <a href={`mailto:${footerContent[lang].email}`}>{footerContent[lang].email}</a>
          </p>

          <h3>8. Whom does the Company share your personal data with? </h3>
          <p>The Company may share your personal data, with: </p>
          <ol type='a'>
            <li>
              <strong>Service Providers:</strong> The company may share your information with third parties who the company has contracted with for delivery of Services.{" "}
            </li>
            <li>
              <strong>Employees/Consultants:</strong> The Company may share your information with its employees and consultants.
            </li>
            <li>
              <strong>Compliance with law:</strong> The Company can share your information when compelled by a government agency, law, regulation, a court or other legal process:
              <ul type='disc'>
                <li>To enforce our Terms of Use or any other agreements we may have with you.</li>
                <li>In order to investigate, respond to or resolve problems or inquiries or </li>
                <li>defend our interests and when you otherwise consent.</li>
              </ul>
            </li>
            <li>
              <strong>Mergers and Acquisitions: </strong>
              The Company reserve the right to share your information in a merger, acquisition, change of control, joint venture or other business combination.
            </li>
          </ol>

          <h2>9. Data Transfers </h2>
          <p>
            The Company may transfer to and store the data collected in countries other than the country in which the data was collected. Those countries may not have similar data protection laws as
            the country in which your data was collected. When we transfer your data, we shall take adequate measures of data protection in accordance with all applicable legal requirements. If you
            are a resident of EEA, then your data shall be transferred only in compliance with the EU-GDPR Regulations.
          </p>
          <p>The company will keep a copy of all the data stored by it in India at all times as required by the applicable law.</p>

          <h2>10. Rights of the Data Subject</h2>
          <p>You shall have the following rights under this Privacy Policy: </p>
          <ol type='a'>
            <li>
              Right to Request access to your personal data: You may, during anytime, after You have given Your consent to the collection of Your data, may request the access to the same, by sending
              an email to <a href={`mailto:${footerContent[lang].email}`}>{footerContent[lang].email}</a>. The Company shall provide you with a reasonable access to Your data within 7 working days or
              within such timeline as mandated under the applicable law.
            </li>
            <li>
              Right to Request correction of your data: You may, during anytime, after You have given Your consent to the collection of Your data, may request the correction of the same, by sending an
              email to <a href={`mailto:${footerContent[lang].email}`}>{footerContent[lang].email}</a>. Please note that failure to do so, shall be Your sole responsibility and the Company shall not
              be responsible for any incorrect information that You may have supplied.
            </li>
            <li>Request erasure of your data.</li>
            <li>Right to demand erasure or deletion of your data.</li>
            <li>
              Right to restrict processing of data: You may at anytime, after You have given consent to the collection of data, request the Company to process or not to process the same in a specific
              manner. You may send an email to <a href={`mailto:${footerContent[lang].email}`}>{footerContent[lang].email}</a> in order to request the same. Subject to the applicable law, the Company
              shall make all reasonable efforts to meet Your request at the earliest. However, You agree, that there could be genuine delays in immediately meeting this request, hence You agree to
              provide the Company 30 working days to implement Your request.{" "}
            </li>
            <li>
              Right to request transfer of data: You may request to get Your data transferred from one jurisdiction to the other. However, please note that the Company shall not take responsibility if
              the jurisdiction where You wish Your data to be transferred has inadequate data protection laws or measures.{" "}
            </li>
          </ol>

          <h2>11. How do we protect your information? </h2>
          <p>We protect Your information by employing the following measures:</p>
          <ol type='a'>
            <li>We have put in place various physical and technical security measures to protect the personal data we collect from you. </li>
            <li>We require those with whom we share Your personal data to enter into confidentiality arrangements with us so that they do not disclose Your personal information. </li>
          </ol>
          <p>
            You acknowledge and agree, that the Company cannot possibly guarantee 100% protection against loss of information. As internet is susceptible to unforeseen security issues, We can only
            make reasonable efforts to protect Your personal data, but cannot give an absolute guarantee regarding the same. We shall not be liable in the event, Your personal data is unauthorizedly
            disclosed on account of improper use or disclosure, unauthorized modification and unlawful destruction or accidental loss of information, or any security breach which is beyond the
            reasonable control of the Company.
          </p>

          <h2>12. Third Party Websites </h2>
          <p>
            The website may contain links to third-party websites. If You choose to click on such links, You would be re-directed to a third-party website. Please note that, Our terms of use and
            privacy policy cease to apply as and when You are redirected to a third party website. You are advised to read the privacy policy of such third-party website before You submit any personal
            data on such a website. We shall not be responsible for any loss of information which may occur on such third-party websites.
          </p>
          <h2>13. Changes to Privacy Policy </h2>
          <p>There may be changes to this Privacy Policy from time to time. The last effective date shall be highlighted at the top of policy information.</p>
          <h2>14. Redressal of Grievances </h2>
          <p>
            If you have any questions or grievances regarding about our website, the content thereof, our data handling policies, or about our Services, you can reach out to The Grievance Officer of
            the Company customer support at <a href={`mailto:${footerContent[lang].email}`}>{footerContent[lang].email}</a>
          </p>
        </div>
      </div>
      <Divider />
    </Layout>
  );
};

export default PrivacyPolicy;
