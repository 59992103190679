import React from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Spin, Alert, Form, Input, Button, message } from "antd";
import { isAuthenticated } from "../../services/auth";
import { useGlobalState, useOCRState } from "../../context/context";

import FileUploadContainer from "../../component/FileUploadContainer/FileUploadContainer";
import FileProcessingContainer from "../../component/FileProcessingContainer/FileProcessingContainer";
import Layout from "../../component/Layout/Layout";
import LogedInFaqs from "../../services/pageContent/logedInFaqs.json";
import paidUploadContent from "../../services/pageContent/paidHomePage.json";

import "./PaidOCR.scss";
import CustomModal from "../../component/CustomModal/CustomModal";
import { postContactUsMail } from "../../services/sendMail";

const AlertBanner = () => {
  const [{ user_info }, dispatch] = useGlobalState();
  const [isContactModal, setContactModal] = React.useState(false);
  const [contactForm] = Form.useForm();

  const [loading, setLoading] = React.useState(false);
  const onFinish = (info) => {
    setLoading(true);
    postContactUsMail(
      info,
      (r) => {
        message.success(r.msg);
        setLoading(false);
        setContactModal(false);
      },
      (err) => {
        message.error(err);
        setLoading(false);
        setContactModal(false);
      }
    );
  };

  const closeModal = () => {
    setContactModal(false);
  };

  const openContactModal = () => {
    contactForm.setFieldsValue({ name: user_info?.name, email: user_info?.email });
    setContactModal(true);
  };
  return (
    <>
      {user_info?.role === "user" && user_info?.leftCredit === 0 && (
        <Alert
          message={
            <div>
              You don't have any left Credit please{" "}
              <span className='contact-us-link' onClick={openContactModal}>
                Contact Us
              </span>{" "}
            </div>
          }
          type='error'
        />
      )}
      {user_info?.role === "user" && user_info?.leftCredit <= 10 && user_info?.leftCredit >= 1 && (
        <Alert
          message={
            <div>
              You have only <span className='left-credit'> {user_info?.leftCredit} Credit Left </span>{" "}
              <span className='contact-us-link' onClick={openContactModal}>
                Contact Us
              </span>{" "}
              or
              <Link to='/upgrade-plan'> Buy Credits.</Link>
            </div>
          }
          type='info'
        />
      )}

      <CustomModal isModalVisible={isContactModal} handleCancel={closeModal} width={450} height={550} borderRadius>
        <div className='conatact-us-modal'>
          <h1 className='constact-modal-title'>Contact Us</h1>
          <Form
            form={contactForm}
            name='contact-us-form'
            layout='vertical'
            requiredMark={false}
            className='contact-us-wrapper'
            onFinish={onFinish}>
            <Form.Item label='Name' name='name'>
              <Input placeholder='Name' />
            </Form.Item>
            <Form.Item label='Email' name='email'>
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item
              label='Requirement'
              name='requirement_desc'
              rules={[
                {
                  required: true,
                  message: "Please write somthing about your requirement!",
                },
              ]}>
              <Input.TextArea rows={5} placeholder='A Breif About Your Requirement' />
            </Form.Item>

            <Form.Item noStyle>
              <Button loading={loading} type='primary' htmlType='submit' className='custom-btn contact-us-btn'>
                Contact Us
              </Button>
            </Form.Item>
          </Form>
        </div>
      </CustomModal>
    </>
  );
};

const PaidOCR = () => {
  const history = useHistory();
  const location = useLocation();
  const [{ user_info, lang }, dispatch] = useGlobalState();
  const [{ filesToProccess }, ocrDispatch] = useOCRState();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (!isAuthenticated()) {
      history.push("/");
    }
  }, []);

  return (
    <div className='home-page-wrapper'>
      <Layout faqs={LogedInFaqs[lang]}>
        <AlertBanner />
        <Spin spinning={loading}>
          <div className='main-content'>
            <h2 className='app-title'>
              {paidUploadContent[lang].title[0]} {paidUploadContent[lang].title[1]}
            </h2>
            <p className='app-desc'>{paidUploadContent[lang].subTitle}</p>
            <div style={{ marginBottom: "3rem", padding: "0 20px" }}>
              <FileUploadContainer freeOCR={false} multiple={true} />
            </div>

            {filesToProccess.length > 0 && (
              <div style={{ paddingBottom: "3rem" }}>
                <FileProcessingContainer freeOCR={false} />
              </div>
            )}
          </div>
        </Spin>
      </Layout>
    </div>
  );
};

export default PaidOCR;
