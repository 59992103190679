import React from "react";
import { Avatar, Carousel, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./ClientFeedback.scss";
import pageContent from "../../services/pageContent/feedback.json";

const FeedbackCard = ({ name, personTitle, feedback, pic_link = "" }) => {
  return (
    <div className='feedback-card'>
      <Space direction='vertical' size='small' src={pic_link} align='center'>
        <Avatar size={80} icon={<UserOutlined />} />
        <div className='person-name'>{name}</div>
        <div className='person-title'>{personTitle}</div>
        <div className='client-feedback'>{feedback}</div>
      </Space>
    </div>
  );
};

const ClientFeedback = () => {
  return (
    <div className='client-feedback-wrapper'>
      <h1 className='section-title' data-aos='fade-down' data-aos-duration='600'>
        {pageContent.title}
      </h1>
      <div data-aos='zoom-in' data-aos-delay='300' data-aos-duration='800'>
        <Carousel autoplay className='feedback-carousel-box'>
          {pageContent.clientFeedbacks.map((f, i) => (
            <FeedbackCard key={i} name={f.name} personTitle={f.workTitle} feedback={f.feedback} pic_link={f.pic_link} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default ClientFeedback;
