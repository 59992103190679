import actionTypes from "./actions";
import { message } from "antd";

export const ocrIniState = {
  filesToProccess: [],
  correctOrient: false,
  enhanceImgQlty: false,
  removeNoise: false,
  fileFormat: "txt",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FILES_TO_PROCESS_PAID:
      return {
        ...state,
        filesToProccess: [...state.filesToProccess, action.payload],
      };
    case actionTypes.FILES_TO_PROCESS_FREE:
      return {
        ...state,
        filesToProccess: [action.payload],
      };
    case actionTypes.OCR_CLEAR_STATE:
      return {
        ...state,
        correctOrient: false,
        enhanceImgQlty: false,
        removeNoise: false,
        fileFormat: "txt",
        filesToProccess: [],
      };
    case actionTypes.PROCESS_MODE:
      const updatedFileToPro = state.filesToProccess.map((f) => {
        if (f.uid === action.payload.uid) {
          return {
            ...f,
            [action.payload.name]: action.payload.value,
            isProcessed: false,
          };
        }
        return f;
      });
      const allCheck = updatedFileToPro.find((f) => f[action.payload.name] === false);

      if (!action.payload.value) {
        return {
          ...state,
          filesToProccess: updatedFileToPro,
          [action.payload.name]: false,
        };
      } else if (allCheck === undefined) {
        return {
          ...state,
          filesToProccess: updatedFileToPro,
          [action.payload.name]: true,
        };
      } else {
        return {
          ...state,
          filesToProccess: updatedFileToPro,
        };
      }

    case actionTypes.FILE_STATUS:
      const updateStatus = state.filesToProccess.map((f) => {
        if (f.uid === action.payload.uid) {
          return {
            ...f,
            status: action.payload.status,
          };
        } else {
          return f;
        }
      });
      return {
        ...state,
        filesToProccess: updateStatus,
      };
    case actionTypes.DOWN_PER:
      const updateDonnPer = state.filesToProccess.map((f) => {
        if (f.uid === action.payload.uid) {
          return {
            ...f,
            downPer: action.payload.downPer,
          };
        }
        return f;
      });
      return {
        ...state,
        filesToProccess: updateDonnPer,
      };
    case actionTypes.DELETE_UPLOAD_FILE:
      const updateDelete = state.filesToProccess.filter((f) => f.uid !== action.payload);
      return {
        ...state,
        filesToProccess: updateDelete,
      };
    case actionTypes.SET_EXTRACT_RESULT:
      const updateResult = state.filesToProccess.map((f) => {
        if (f.uid === action.payload.uid) {
          return {
            ...f,
            isSuccess: action.payload.isSuccess,
            extractionResult: action.payload.extractionResult,
            isErrMsg: action.payload.isErrMsg,
            isError: action.payload.isError,
            resTime: action.payload.resTime,
            size: action.payload.size ?? f.size,
            status: action.payload.status,
            isProcessed: action.payload.isProcessed,
          };
        }
        return f;
      });
      return {
        ...state,
        filesToProccess: updateResult,
      };
    case actionTypes.OCR_REFRESH:
      const refreshResult = state.filesToProccess.map((f) => {
        return {
          ...f,
          isSuccess: false,
          extractionResult: null,
          isErrMsg: "",
          isError: false,
          resTime: null,
        };
      });
      return {
        ...state,
        filesToProccess: refreshResult,
      };

    case actionTypes.SET_FILE_FORMAT:
      let updatedFILEFORMAT = state.filesToProccess.map((f) => {
        if (f.uid === action.payload.uid) {
          return {
            ...f,
            fileFormat: action.payload.format,
            isProcessed: false,
          };
        }
        return f;
      });
      const allSameFormat = updatedFILEFORMAT.find((f) => f.fileFormat !== action.payload.format);
      return {
        ...state,
        filesToProccess: updatedFILEFORMAT,
        fileFormat: allSameFormat === undefined ? action.payload.format : "",
      };
    case actionTypes.SET_ALL_FILEFORMAT:
      const allFileFormat = state.filesToProccess.map((f) => {
        return {
          ...f,
          fileFormat: action.payload,
          isProcessed: false,
        };
      });
      return {
        ...state,
        filesToProccess: allFileFormat,
        fileFormat: action.payload,
      };
    case actionTypes.ALL_PROCESS_MODE:
      const allModeUpdate = state.filesToProccess.map((f) => {
        return {
          ...f,
          [action.payload.name]: action.payload.value,
          isProcessed: false,
        };
      });

      return {
        ...state,
        filesToProccess: allModeUpdate,
        [action.payload.name]: action.payload.value,
      };
    case actionTypes.SELECT_PAGES:
      const file = action.payload.file;
      const value = action.payload.value;
      const updateSelectPage = state.filesToProccess.map((f) => {
        if (f.uid === file.uid) {
          let pageNo = value;
          // console.log(pageNo);
          if (pageNo.length === 1) {
            if (pageNo <= file.pages) {
              return {
                ...f,
                selectedPdfPage: pageNo,
                isProcessed: false,
              };
            } else {
              message.error("Please enter correct no of page");
              return { ...f };
            }
          } else if (pageNo.length > 1 && pageNo.includes("-")) {
            let splitNo = pageNo.split("-");
            if (splitNo[1] <= file.pages && splitNo[0] < file.pages) {
              return { ...f, selectedPdfPage: pageNo, isProcessed: false };
            } else {
              message.error("Please enter correct no of page");
              return { ...f };
            }
          } else if (pageNo.length > 1 && pageNo.includes(",")) {
            let splitNo = pageNo.split(",");
            let checkNo;
            splitNo.map((n, i) => {
              if (Number(n) <= file.pages) {
                checkNo = { ...f, selectedPdfPage: pageNo, isProcessed: false };
              } else {
                message.error("Please enter correct no of page");
                checkNo = { ...f };
              }
            });
            return { ...checkNo };
          } else if (pageNo.length > 1) {
            if (pageNo <= file.pages) {
              return {
                ...f,
                selectedPdfPage: pageNo,
                isProcessed: false,
              };
            } else {
              message.error("Please enter correct no of page");
              return { ...f };
            }
          } else {
            return { ...f, selectedPdfPage: pageNo, isProcessed: false };
          }
        } else {
          return f;
        }
      });
      return {
        ...state,
        filesToProccess: updateSelectPage,
      };
    default:
      return state;
  }
};

export default reducer;
