import React from "react";
import { Row, Divider, Col, Space, Button, Checkbox, Select, Popconfirm, Tooltip, message, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons/lib/icons";
import { cancelReq } from "../../services/getOCRResult";

import BorderDases from "../../assest/border-dases.svg";
import AnimateGif from "../../assest/animated-clock.gif";
import ClockIcon from "../../assest/clock-icon.svg";

import { getOCRResult } from "../../services/getOCRResult";
import { pageNumberList } from "../../services/utils/ocr_utils";
import FileViewCard from "./FileViewCard";
import FileResultViewCard from "./FileResultViewCard";
import { bytesToSize, getReadableTime, msgModal, truncateString } from "../utils/utils";
import { useGlobalState, useOCRState } from "../../context/context";
import { uploadProcessFile } from "../../services/uploadProcessService";
import actionTypes from "../../context/actions";
import { fileLimit } from "../FileUploadContainer/FileUploadContainer";
import "./FileProcessingContainer.scss";

const { Option } = Select;
message.config({
  top: 0,
  duration: 5,
  maxCount: 1,
});

const FileProcessingContainer = ({ freeOCR }) => {
  const [globalState, dispatch] = useGlobalState();
  const [{ filesToProccess, correctOrient, removeNoise, fileFormat }, ocrDispatch] = useOCRState();

  const [imgOverlay, setImgOverlay] = React.useState(false);
  // check mode opts
  const onCheckModeOpt = (e, uid) => {
    ocrDispatch({ type: actionTypes.PROCESS_MODE, payload: { name: e.target.name, value: e.target.checked, uid: uid } });
  };

  // cancel or delete all file upload files to process
  const confirmCancel = (e) => {
    e.preventDefault();
    Object.keys(cancelReq).map((key, index) => {
      cancelReq[key]("request cancelled");
    });

    ocrDispatch({ type: actionTypes.OCR_CLEAR_STATE, payload: {} });
  };

  // change file format for all
  const fileFormateForAll = (format) => {
    if (format) {
      ocrDispatch({ type: actionTypes.SET_ALL_FILEFORMAT, payload: format });
    }
  };
  // check mode opts for all
  const onCleckModeOptForAll = (e) => {
    ocrDispatch({ type: actionTypes.ALL_PROCESS_MODE, payload: { name: e.target.name, value: e.target.checked } });
  };

  // set process status
  const onRefresOCRProcess = () => {
    ocrDispatch({
      type: actionTypes.OCR_REFRESH,
      payload: {},
    });
  };

  // Save process file in DB when user login
  const saveProcessFileToDB = (file, r) => {
    uploadProcessFile(
      {
        user_id: globalState.user_info?.user_id,
        file_uid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
        success: r.success,
        extractionResult: r.data,
        noOf_pages: r.totalPage,
        role: globalState.user_info?.role,
      },
      () => {
        dispatch({ type: actionTypes.REFRESH, payload: {} });
      },
      () => {}
    );
  };

  // main function to process the image and pdf
  const onGetOCR = async () => {
    // if user offline
    if (globalState.isOffline) return msgModal("error", "Unable to process, Please check your Network");
    // if user add more than fileLimit
    if (filesToProccess.length > fileLimit && globalState.user_info?.role === "user")
      return msgModal("info", `Sorry you can't process more than ${fileLimit} files at ones!`, "Info");

    // if user loged in
    if (!freeOCR) {
      if (!globalState.user_info?.name) return msgModal("error", "Oops!, Something wents wrong please try again later ");
      const isFileLeft = filesToProccess.find((f) => f.isSuccess === false || f.isProcessed === false);
      if (isFileLeft === undefined) return message.success("files are Converted!");
    }

    if (!freeOCR) {
      const totalPages = filesToProccess.reduce(function (acc, obj) {
        return acc + pageNumberList(obj.selectedPdfPage, obj.pages).length;
      }, 0);
      if (globalState.user_info?.leftCredit < totalPages && globalState.user_info?.role === "user")
        return msgModal("info", "Unable to process, You don't have enough Credit!", "Oops!");
    }

    dispatch({ type: actionTypes.IS_PROCESSING, payload: true });

    const allPromise = filesToProccess.map((file) => {
      if (!file.selectedPdfPage) return msgModal("info", `Please enter any page no of : ${file.name}`, "Info");

      // if user use free version set process to processing
      if (freeOCR) onRefresOCRProcess();

      // if user loged in process file only if not success or not error in file
      if (!file.isSuccess || !file.isProcessed) {
        if (file.status === "success") {
          ocrDispatch({
            type: actionTypes.FILE_STATUS,
            payload: { status: "re-proccessing", uid: file.uid },
          });
        } else {
          ocrDispatch({
            type: actionTypes.FILE_STATUS,
            payload: { status: "proccessing", uid: file.uid },
          });
        }

        // start count time
        let startTime = Date.now();
        return getOCRResult(freeOCR, file, (downPer, uid) => {
          // set download per after procssing file
          ocrDispatch({ type: actionTypes.DOWN_PER, payload: { downPer: downPer, uid: uid } });
        })
          .then((r) => {
            // if error in frontend with axios request
            if (!r.succes) {
              ocrDispatch({
                type: actionTypes.SET_EXTRACT_RESULT,
                payload: {
                  uid: file.uid,
                  isSuccess: false,
                  extractionResult: [],
                  status: "error",
                  isErrMsg: r.message,
                  isError: false,
                  size: r.bytesLength,
                  isProcessed: false,
                },
              });
            }
            if (r.success) {
              let resTime = Date.now() - startTime;
              if (r.isTexDetectd) {
                ocrDispatch({
                  type: actionTypes.SET_EXTRACT_RESULT,
                  payload: {
                    uid: file.uid,
                    isSuccess: r.success,
                    extractionResult: r.data,
                    status: "success",
                    isErrMsg: "",
                    isError: false,
                    resTime: resTime,
                    size: r.bytesLength,
                    isProcessed: true,
                  },
                });
              } else if (!r.isTexDetectd) {
                ocrDispatch({
                  type: actionTypes.SET_EXTRACT_RESULT,
                  payload: {
                    uid: file.uid,
                    isSuccess: r.success,
                    extractionResult: r.data,
                    status: "error",
                    isErrMsg: r.message,
                    isError: true,
                    resTime: resTime,
                    size: r.bytesLength,
                    isProcessed: true,
                  },
                });
              }
              if (!freeOCR && r.isTexDetectd) {
                saveProcessFileToDB(file, r);
              }
            }
          })
          .catch((err) => {
            return msgModal("error", "Something wents wrong Please try again later", "Unable to process");
          });
      }
    });

    Promise.all(allPromise).then((r) => {
      dispatch({ type: actionTypes.IS_PROCESSING, payload: false });
    });
  };

  React.useEffect(() => {
    if (globalState.isOffline) {
      msgModal("error", "Please check your network.");
      Object.keys(cancelReq).map((key, index) => cancelReq[key]("Network Error"));
      dispatch({ type: actionTypes.IS_PROCESSING, payload: false });
    }
  }, [globalState.isOffline]);

  if (freeOCR) {
    return (
      <div className='file-processing-wrapper freeOCR'>
        <Row gutter={[24, 0]} justify='space-between'>
          <Col xs={24} md={24} lg={10} style={{ zIndex: 10 }}>
            <div className='file-view-box'>
              <Row justify='space-between' align='middle' className='file-view-header'>
                <Col md={12} sm={12}>
                  <Tooltip title={filesToProccess[0].name}>
                    <h4 className='view-file-name label-dark'>
                      {truncateString(filesToProccess[0].name, 24)} ({bytesToSize(filesToProccess[0].size)})
                    </h4>
                  </Tooltip>
                </Col>
                <Col md={12} sm={12}>
                  <Space size='middle' align='center' style={{ float: "right" }}>
                    {filesToProccess[0].isSuccess && (
                      <Button className='view-action-btn' onClick={() => setImgOverlay(true)}>
                        Show Overlay
                      </Button>
                    )}
                    <Button
                      className='view-action-btn '
                      disabled={globalState.isProcessing}
                      onClick={() => {
                        Object.keys(cancelReq).map((key, index) => {
                          cancelReq[key]("Cancelled by user");
                        });
                        ocrDispatch({ type: actionTypes.OCR_CLEAR_STATE, payload: {} });
                      }}>
                      Clear Image
                    </Button>
                  </Space>
                </Col>
              </Row>
              <div className='file-view-cards'>
                {filesToProccess.map((file, index) => {
                  return (
                    <div key={file.uid}>
                      <FileViewCard file={file} freeOCR={freeOCR} onCheckModeOpt={onCheckModeOpt} isProcessing={globalState.isProcessing} />
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>

          {/* controler */}
          <Col xs={24} md={24} lg={5}>
            <Row justify='space-between' className='process-controler-row'>
              <Col lg={24} md={18}>
                <div className='file-upload-mode'>
                  <Space direction='vertical'>
                    <Space direction='vertical'>
                      <Checkbox
                        disabled={globalState.isProcessing}
                        className='checkbox-label'
                        name='correctOrient'
                        checked={filesToProccess[0].correctOrient}
                        onChange={(e) => onCheckModeOpt(e, filesToProccess[0].uid)}>
                        Correct Orientation
                      </Checkbox>
                      <Checkbox
                        disabled={globalState.isProcessing}
                        className='checkbox-label'
                        name='removeNoise'
                        checked={filesToProccess[0].removeNoise}
                        onChange={(e) => onCheckModeOpt(e, filesToProccess[0].uid)}>
                        Remove Noise
                      </Checkbox>
                    </Space>
                    <Button loading={globalState.isProcessing} onClick={onGetOCR} className={`get-orc-btn btn-active`}>
                      {filesToProccess[0].isSuccess || filesToProccess[0].isError ? "Refresh OCR" : "Get OCR"}
                    </Button>
                    {!filesToProccess[0].isSuccess && (
                      <>
                        <img className='border-arrow-dases' src={BorderDases} alt='Border Dases' />
                      </>
                    )}
                  </Space>
                </div>
              </Col>
              {filesToProccess[0].isSuccess && (
                <Col lg={24} md={4}>
                  <Space className='time-taken-view'>
                    <div className='clock-icon'>
                      <img src={ClockIcon} alt='Clock Icon' />
                    </div>
                    <Space direction='vertical'>
                      <div className='time-taken-label'>Time Taken</div>
                      <div className='time-taken'>{getReadableTime(filesToProccess[0].resTime)}</div>
                    </Space>
                  </Space>
                </Col>
              )}
            </Row>
          </Col>

          {/* File Result View Container */}
          <Col xs={24} md={24} lg={9}>
            <div className='file-result-box'>
              {filesToProccess.map((f, i) => {
                if (f.isSuccess && !f.isError) {
                  return (
                    <FileResultViewCard key={f.uid} file={f} imgOverlay={imgOverlay} setImgOverlay={setImgOverlay} freeOCR={freeOCR} />
                  );
                }
              })}
              {globalState.isProcessing && (
                <>
                  <div className='processing-wait-card'>
                    <span>
                      Please wait Coversion in Progress... <img width='50px' src={AnimateGif} alt='loading Icon' />
                    </span>
                  </div>
                </>
              )}
              {!filesToProccess[0].isSuccess && filesToProccess[0].isError && (
                <>
                  <div className='processing-wait-card error-msg-card'>
                    <span>Text not detected </span>
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className='file-processing-wrapper paidOCR'>
        <Row gutter={[48, 0]} justify='space-between'>
          {/* File upload View Container */}
          <Col lg={13} md={24} style={{ zIndex: 10 }}>
            <div className='file-view-box'>
              <Row gutter={[0, 12]} justify='space-between' align='middle' className='file-view-header'>
                <Col sm={4} xm={12}>
                  <div className='no-of-files'>{filesToProccess.length} files selected</div>
                </Col>
                <Col sm={3} xm={12}>
                  <Popconfirm
                    title='Are you sure want to cancel?'
                    onConfirm={confirmCancel}
                    onCancel={() => {}}
                    okText='Yes'
                    cancelText='No'>
                    <Tooltip title='Cancel All'>
                      <Button
                        className={`custom-btn-style process-cancel-btn ${globalState.isProcessing ? "btn-deactive" : "btn-active "}`}>
                        Cancel All
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Col>
                <Col sm={8} xm={16} className='mode-check-boxs-all'>
                  <Space direction='vertical' size={0}>
                    <Checkbox
                      disabled={globalState.isProcessing}
                      className='checkbox-label'
                      name='correctOrient'
                      checked={correctOrient}
                      onChange={(e) => onCleckModeOptForAll(e)}>
                      Correct Orientation All
                    </Checkbox>
                    <Checkbox
                      disabled={globalState.isProcessing}
                      className='checkbox-label'
                      name='removeNoise'
                      checked={removeNoise}
                      onChange={(e) => onCleckModeOptForAll(e)}>
                      Remove Noise All
                    </Checkbox>
                  </Space>
                </Col>
                <Col sm={7} xm={8} className='convert-format'>
                  <Space align='center' className='align-right'>
                    <span className='checkbox-label'>Convert All</span>
                    <Select
                      defaultValue='TXT'
                      disabled={globalState.isProcessing}
                      value={fileFormat}
                      onChange={(fileFormat) => fileFormateForAll(fileFormat)}
                      className='select-format'
                      style={{ width: 80 }}>
                      <Option value=''>Format</Option>
                      <Option value='txt'>TXT</Option>
                    </Select>
                  </Space>
                </Col>
              </Row>
              <Divider style={{ margin: "10px 0" }} />
              <div className='file-view-cards'>
                {filesToProccess.map((file, index) => {
                  return (
                    <div key={file.uid}>
                      <FileViewCard file={file} onCheckModeOpt={onCheckModeOpt} isProcessing={globalState.isProcessing} />
                      <Divider style={{ margin: "10px 0" }} />
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>

          {/* File Result View Container */}
          <Col lg={11} md={24} className='w-100' style={{ width: "100%" }}>
            <div className='file-result-box'>
              {filesToProccess.map((f, i) => {
                if (f.isSuccess || f.extractionResult?.length > 0) {
                  return <FileResultViewCard key={f.uid} file={f} />;
                }
              })}
              {globalState.isProcessing && (
                <>
                  <div className='processing-wait-card'>
                    <span>
                      Please wait.Coversion in Progress... <LoadingOutlined />
                    </span>
                  </div>
                </>
              )}
              {!globalState.isProcessing && (
                <div>
                  <Button
                    disabled={globalState.isProcessing}
                    onClick={onGetOCR}
                    className={`get-orc-btn btn-active ${
                      !filesToProccess.find((f) => f.extractionResult?.length > 0 || f.isSuccess) ? "marginTop" : ""
                    } `}>
                    Get OCR
                  </Button>
                  <br />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};

export default FileProcessingContainer;
