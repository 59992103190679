import React, { useEffect } from "react";
import "./PasswordResetPage.scss";
import { Row, Col, Space, Form, Tooltip, Select, Input, Button, Alert } from "antd";
import AlazkaLogo from "../../assest/alazka-logo.png";
import { setLocalStorage, getLocalStorage } from "../../component/utils/utils";
import { useParams, useHistory, Link } from "react-router-dom";

import { resetPassword } from "../../services/auth";

const Option = Select;

const PasswordRestPage = () => {
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [isError, setError] = React.useState("");
  const [isMsg, setMsg] = React.useState("");

  const [form] = Form.useForm();

  const onResetPass = (resetPsInfo) => {
    if (!/[A-Z]/.test(resetPsInfo.password)) {
      form.setFields([
        {
          name: "password",
          errors: ["password should cantain atleast one uppercase letter!"],
        },
      ]);
      return false;
    }

    if (!/[a-z]/.test(resetPsInfo.password)) {
      form.setFields([
        {
          name: "password",
          errors: ["password should cantain atleast one lowercase letter!"],
        },
      ]);
      return false;
    }
    if (!/[0-9]/.test(resetPsInfo.password)) {
      form.setFields([
        {
          name: "password",
          errors: ["password should cantain atleast one number!"],
        },
      ]);
      return false;
    }
    if (!/[#?!@$%^&*-]/.test(resetPsInfo.password)) {
      form.setFields([
        {
          name: "password",
          errors: ["password should cantain atleast one special caracter #?!@$%^&*-"],
        },
      ]);
      return false;
    }

    setError("");
    setMsg("");
    setLoading(true);
    resetPassword(
      resetPsInfo,
      params.token,
      (r) => {
        setLoading(false);
        setMsg(r.msg);
        setError("");
        form.resetFields(["password", "confirmPassword"]);
        if (r.success) {
          history.push({ pathname: "/", state: { loginModal: true } });
        }
      },
      (err) => {
        setLoading(false);
        setError(err.msg);
        setMsg("");
      }
    );
  };

  const onBackToHomePage = () => {
    history.push(`/`);
  };

  return (
    <div className='password-reset-wrapper'>
      <Row className='header_container' justify='space-between' align='middle'>
        <Col>
          <Space align='center'>
            <div className='app-logo'>
              <img src={AlazkaLogo} alt='Alazka' />
            </div>
            {/* <div className='vt-line'>|</div>
            <div className='app-header-desc'>Deep Dive Into Unstructured Data</div> */}
          </Space>
        </Col>
      </Row>

      <div className='password-rest-wrapper'>
        {isError && <Alert message={isError} type='error' showIcon={false} closable />}
        {isMsg && <Alert message={isMsg} type='success' showIcon={false} closable />}
        <h1>Reset your password</h1>
        <Form form={form} name='password-reset-form' layout='vertical' initialValues={{}} requiredMark={false} onFinish={onResetPass}>
          <Form.Item name='password' hasFeedback rules={[{ required: true, message: "password is required!", min: 5 }]}>
            <Input.Password placeholder='Password' />
          </Form.Item>
          <Form.Item
            name='confirmPassword'
            dependencies={["password"]}
            hasFeedback
            rules={[
              { required: true, message: "confirm password is required!", min: 5 },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The password that you entered do not match!"));
                },
              }),
            ]}>
            <Input.Password placeholder='Confirm Password' />
          </Form.Item>
          <Form.Item noStyle>
            <Button loading={loading} disabled={loading} type='primary' htmlType='submit' className='custom-btn login-btn'>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
        <div className='back-to-home' onClick={onBackToHomePage}>
          Back to Home Page
        </div>
      </div>

      <Row justify='space-between' align='middle' className='footer-section footer-sec-bottom'>
        <Col>
          <div className='app-logo'>
            <img src={AlazkaLogo} alt='Alazka' />
          </div>
        </Col>
        <Col>
          <div>
            <Link to='/terms-of-service' className='terms-service' style={{ textAlign: "center" }}>
              Terms of Service
            </Link>{" "}
            <span className='vt-line'>|</span>{" "}
            <Link className='terms-service' to='/privacy-policy'>
              Privacy Policy
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PasswordRestPage;
