import React from "react";
import { Collapse, Select } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import "./FAQS.scss";
const { Panel } = Collapse;

const FAQS = ({ faqs }) => {
  return (
    <div className='faq-section' data-aos='fade-up' data-aos-duration='600' data-aos-anchor-placement='top-bottom'>
      <h2 className='faq-title' data-aos='fade-down' data-aos-duration='600' data-aos-delay='300'>
        {faqs.title}
      </h2>
      <div className='faq-accordian'>
        <Collapse bordered={false} defaultActiveKey={["1"]} accordion expandIconPosition='right' expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}>
          {faqs.faqs.map((f, i) => (
            <Panel header={<h3 className='faq-question'>{f.question}</h3>} key={i + 1}>
              <p style={{ paddingLeft: 24 }}>{f.ans}</p>
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default FAQS;
